import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  useTheme,
  IconButton,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useSelector } from "react-redux";
import Sockets from "../../../../../utils/sockets";
import Papa from "papaparse";

const TopWater = (props) => {
  const socketapiData = useSelector((state) => state?.users?.apiInfo);
  const socketuser = useSelector((state) => state?.users?.userInfo);

  const { readData } = Sockets(socketapiData, socketuser);

  const theme = useTheme();
  const { TopwaterPopup, setTopwaterPopup, title } = props;
  const { reportFile } = useSelector((state) => state?.todos);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [textContent, setTextContent] = useState("");
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const processFiles = async () => {
      if (reportFile && reportFile?.generation?.file) {
        const file = reportFile?.generation?.file;
        try {
          const result = await readData(file, "eod");
          let csvString = await Papa.parse(result);
          setData(csvString?.data);
          setTextContent(result);
          setShowLoader(false);
        } catch (error) {
          console.error("Error parsing CSV:", error);
          setShowLoader(false);
        }
      } else {
        setShowLoader(false);
        setTextContent("No file content available.");
      }
    };

    processFiles();
  }, [reportFile]);

  const handleCopy = () => {
    navigator.clipboard.writeText(textContent);
    alert("Text copied to clipboard!");
  };

  const handleDownload = () => {
    const csvContent = Papa.unparse(data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "report.csv";
    link.click();
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={TopwaterPopup}
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            minWidth: "500px",
            marginLeft: "200px",
          },
        }}
        onClose={() => {
          setTopwaterPopup(false);
          setTextContent("");
        }}
      >
        <Box>
          <DialogTitle sx={{ py: 1 }}>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h6"
                component="div"
                style={{ flexGrow: 1, fontSize: "20px" }}
              >
                {title}'s Report
              </Typography>
              <IconButton
                onClick={() => {
                  setTopwaterPopup(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ py: 1 }}>
            <Box height="100%" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center">
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: "#f5f5f5",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "16px",
                    maxHeight: "350px",
                    overflowY: "auto",
                    wordWrap: "break-word",
                    m: 2,
                    position: "relative",
                  }}
                >
                  {showLoader ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      height="100%"
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {textContent ? (
                        <>
                          <IconButton
                            onClick={handleCopy}
                            sx={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              zIndex: 1,
                            }}
                          >
                            <FileCopyIcon />
                          </IconButton>

                          <Typography
                            variant="body1"
                            sx={{
                              mb: 2,
                              paddingTop: "40px",
                            }}
                          >
                            {textContent}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ textAlign: "center" }}
                        >
                          No file data available.
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={handleDownload}>
                  Download Report
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default TopWater;
