import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { sync } from "../../../../utils/sync";
import { useDispatch } from "react-redux";
import LoaderPopup from "../../../Loader/LoaderPopup"; // Ensure the correct path

const Sync = ({ setIsSync }) => {
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [loadingItems, setLoadingItems] = React.useState([]);
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const dispatch = useDispatch();

  const labels = [
    "Trades",
    "Signals",
    "Lots",
    "Strategies",
    "Brokers",
    "Funds",
    "Custodians",
    "Accountings",
    "Notifications",
    "Routes",
    "Market Data",
  ];

  const handleCheckboxChange = (event) => {
    const label = event.target.name;
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, label]);
    } else {
      setSelectedItems((prev) => prev.filter((item) => item !== label));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setIsSync(false);
    setIsPopupOpen(true);
    setLoadingItems(
      selectedItems.map((item) => ({ name: item, status: "loading" }))
    );

    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];
      try {
        const result = await sync([item]);

        if (result && result.code === 200) {
          setLoadingItems((prev) =>
            prev.map((loadingItem, index) =>
              index === i
                ? { name: loadingItem.name, status: "completed" }
                : loadingItem
            )
          );
        } else {
          setLoadingItems((prev) =>
            prev.map((loadingItem, index) =>
              index === i
                ? { name: loadingItem.name, status: "failed" }
                : loadingItem
            )
          );
        }
      } catch (error) {
        setLoadingItems((prev) =>
          prev.map((loadingItem, index) =>
            index === i
              ? { name: loadingItem.name, status: "failed" }
              : loadingItem
          )
        );
      }
    }
    setTimeout(() => setIsPopupOpen(false), 3000); // Close popup after 3 seconds
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={0}>
        {labels.map((label) => (
          <Grid item xs={12} key={label}>
            <FormControlLabel
              control={
                <Checkbox name={label} onChange={handleCheckboxChange} />
              }
              label={label}
              sx={{ marginTop: "1px" }}
            />
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setIsSync(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>

      <LoaderPopup
        open={isPopupOpen}
        loadingItems={loadingItems}
        setIsSync={setIsSync}
      />
    </Box>
  );
};

export default Sync;
