import React, { useState } from "react";
import { InputAdornment, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { StyledRoot, StyledSearch } from "../../Methods";

const ListToolbar = ({ filterName, onFilterName }) => {
  return (
    <>
      <StyledRoot>
        <Box>
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ color: "text.disabled", width: 15, height: 15 }}
                />
              </InputAdornment>
            }
          />
        </Box>
      </StyledRoot>
    </>
  );
};

export default ListToolbar;
