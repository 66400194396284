import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TABLE_HEAD_SIGNAL_LOCATES,
  findStrategy,
  findFund,
  findFundById,
  findFundByName,
  hashInvestmentReverse,
} from "../../../../Methods";
import SignalsLimitedByLocates from "../../DataEntity/SignalsLimitedByLocates";
import { getSecuritiesWithMarketDataRequest } from "../../../../../utils/helpers";
import RebalancingInfo from "..";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
  updateRebalancerFund,
} from "../../../../../Redux/Reducers/todoReducers";
import { clearRebalancer } from "../../../../../Redux/Actions/todoActions";

const SleeveRebalance = ({
  setCurrentStep,
  setIgnoredData,
  selectedFund,
  setSelectedFund,
}) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [ignoredData, setIgnoredata] = useState([]);
  const [selectedValue, setSelectedValue] = useState("all");
  const [selectedFundId, setSelectedFundId] = useState(null);
  const [strategyList, setStrategyList] = useState([]);
  const [strategyListToggle, setStrategyListToggle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSignalsTable, setShowSignalsTable] = useState(false);
  const [strategyValObj, setstrategyValObj] = useState([]);
  const {
    accountingData,
    strategies,
    funds,
    marketData,
    lots,
    livePrices,
    markets,
  } = useSelector((state) => state?.todos);

  const myFunds = accountingData[0]?.funds;
  const FundList = (funds?.map((i) => i?.abbreviation) || [])?.sort();

  useEffect(() => {}, [livePrices]);

  const findMyStrategies = (fundName, toggle) => {
    if (!fundName) {
      setStrategyList([]);
      setStrategyListToggle([]);
      return;
    }

    const selectedFund = myFunds?.find(
      (fund) => findFund(fund?.fund, funds) === fundName
    );

    if (!selectedFund) {
      setStrategyList([]);
      setStrategyListToggle([]);
      return;
    }

    const strategyAUMsData = Object.entries(
      selectedFund?.strategyAUMs || {}
    ).reduce((acc, [strategyId, strategyAUM]) => {
      const strategyName = findStrategy(strategyId, strategies);
      if (strategyName && strategyAUM !== undefined) {
        acc.push({
          strategyName,
          strategyAUM,
          strategyId,
        });
      }
      return acc;
    }, []);

    const sortedStrategyAUMsData = strategyAUMsData.sort((a, b) =>
      a.strategyName.localeCompare(b.strategyName)
    );

    if (toggle) {
      const enrichedStrategyData = sortedStrategyAUMsData.map((item) => {
        const fullStrategy = strategies?.find(
          (strategy) => strategy.strategyId === item.strategyId
        );
        return fullStrategy ? { ...item, ...fullStrategy } : item;
      });
      setStrategyListToggle(enrichedStrategyData);
    }

    setStrategyList(sortedStrategyAUMsData.map((item) => item.strategyName));
  };

  const handleFetchData = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowSignalsTable(true);
    }, 2000);

    if (strategyListToggle.length > 0) {
      const strategyIds = strategyListToggle.map((item) =>
        item?.strategyId?.toString()
      );
      const data = getSecuritiesWithMarketDataRequest(
        lots,
        selectedFundId,
        strategyIds,
        strategies,
        livePrices
      );
      setData(data?.securities);

      if (data?.market_data_request && data.market_data_request.length > 0) {
        data.market_data_request.forEach((request) => {
          const socketRequest = {
            action: "market_data",
            data: request,
          };

          dispatch(incrementActiveRequests());
          window.clientSocks.send(JSON.stringify(socketRequest));
        });
      }
    }
  };

  const setStrategiesToggle = (newValue) => {
    const enrichedStrategyData = newValue?.map((item) => {
      const fullStrategy = strategies?.find(
        (strategy) => strategy.name === item
      );

      return fullStrategy ? fullStrategy : item;
    });
    setStrategyListToggle(enrichedStrategyData);
  };

  const resetToBack = () => {
    setShowSignalsTable(false);
    setStrategyList([]);
    setStrategyListToggle([]);
    setData([]);
  };
  const onNext = () => {
    if (strategyListToggle.length > 0) {
      const strategyIds = strategyListToggle?.map((item) =>
        item?.strategyId?.toString()
      );
      const transformedIgnoredData = ignoredData?.map((item) => {
        return {
          symbol: item?.ticker,
          investment: hashInvestmentReverse(item?.investment),
          exchange: 4,
          currency: 7,
        };
      });
      setIgnoredData(transformedIgnoredData);
      dispatch(incrementActiveRequests());
      dispatch(updateLoading(true));
      dispatch(updateLoadingMsg("Checking Blacklist"));
      const socketRequest = {
        action: "rebalancer_check_blacklist",
        data: {
          fundId: findFundByName(selectedFund, funds),
          rebalancerType: 0,
          strategies: strategyIds,
          ignoredTickers: transformedIgnoredData,
        },
      };
      window.clientSocks.send(JSON.stringify(socketRequest));
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleFundChange = (event, newValue) => {
    const myFund = findFundById(newValue, funds);
    setSelectedFundId(myFund);
    dispatch(updateRebalancerFund(myFund));
    if (myFund) {
      const fund = findFund(myFund, funds);
      setSelectedFund(fund);
      findMyStrategies(fund, true);
    }
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <FormControl component="fieldset">
                <RadioGroup
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.target.value)}
                  name="radio-buttons-group"
                  column
                  sx={{ width: "90vh" }}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="Rebalance All Strategies in a Fund"
                    onClick={resetToBack}
                  />
                  {selectedValue === "all" && (
                    <Grid item xs={12}>
                      <FormControl sx={{ marginLeft: 2, my: 3, width: "73%" }}>
                        <Autocomplete
                          options={FundList}
                          getOptionLabel={(option) => option}
                          onChange={handleFundChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Fund to Rebalance"
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <FormControlLabel
                    value="specific"
                    control={<Radio />}
                    label="Rebalance Specific Strategies"
                    onClick={resetToBack}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {selectedFund &&
              (selectedValue === "all" ||
                (selectedValue === "specific" &&
                  strategyValObj?.length > 0)) && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <></> */}

                  <Button
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    onClick={onNext}
                    sx={{ width: "150px", height: "40px" }}
                  >
                    Next
                  </Button>
                </Box>
              )}
          </div>
        </Grid>

        {selectedValue === "specific" && (
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl sx={{ width: "40%" }}>
                <Autocomplete
                  options={FundList}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    const myFund = findFundById(newValue, funds);
                    setSelectedFundId(myFund);
                    dispatch(updateRebalancerFund(myFund));
                    if (myFund) {
                      const fund = findFund(myFund, funds);

                      setSelectedFund(fund);
                      findMyStrategies(fund, false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Fund"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </div>
          </Grid>
        )}

        {selectedFund && selectedValue === "specific" && (
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl sx={{ width: "40%" }}>
                <Autocomplete
                  multiple
                  options={strategyList}
                  onChange={(e, newValue) => {
                    setStrategiesToggle(newValue);
                    setstrategyValObj(newValue);
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Strategies"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </div>
          </Grid>
        )}

        {selectedFund && (
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <RebalancingInfo fund={selectedFund} />
            </div>
          </Grid>
        )}

        {selectedFund &&
          (selectedValue === "all" ||
            (selectedValue === "specific" && strategyValObj?.length > 0)) && (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleFetchData}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Fetch Securities & Signals"
                  )}
                </Button>
              </div>
            </Grid>
          )}

        {((selectedValue === "all" && showSignalsTable) ||
          (selectedValue === "specific" &&
            showSignalsTable &&
            strategyValObj?.length > 0)) && (
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <SignalsLimitedByLocates
                markets={markets}
                dummy={data}
                addCol={true}
                tableHeader={TABLE_HEAD_SIGNAL_LOCATES}
                marketData={livePrices}
                showPopup={true}
                strategyListToggle={strategyListToggle}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SleeveRebalance;
