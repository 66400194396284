import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import VantageListHead from "../../../ListHead/SignalList";
import { Tablecell, applySortFilter, getComparator } from "../../../Methods";

const OutputOrders = ({ output, tableHeader }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("shares");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const filteredUsers = applySortFilter(
      output,
      getComparator(order, orderBy)
    );

    setData(filteredUsers);
  }, [order, orderBy, output]);

  return (
    <>
      <Typography variant="h5" component="div" gutterBottom>
        Resulting Trade Entries
      </Typography>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "100%",
          marginRight: 1,
        }}
      >
        {data && data?.length > 0 ? (
          <Box
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "3px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#c0c0c0",
                borderRadius: "10px",
                minHeight: "2px",
              },
            }}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                borderCollapse: "collapse",
                border: "solid 1px #eee",
                marginTop: "-1px",
              }}
            >
              <VantageListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHeader}
                noStatus={true}
                customizeFont={12}
                rowCount={data.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        background: theme.palette.grey[200],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.strategy}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.strategy}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.activity}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        borderLeft: "none",
                        borderRight: "none",
                        paddingRight: "5px",
                      }}
                    >
                      <Box sx={{ width: "100px" }}>{row?.shares}</Box>
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.broker}
                    </Tablecell>

                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.custodian}
                    </Tablecell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default OutputOrders;
