import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { calculateAndFormatNoAbs } from "../Methods";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg
} from "../../Redux/Reducers/todoReducers";
import { useDispatch, useSelector } from "react-redux";

const EditAccounting = ({ recordForEdit, setOpenPopup }) => {
  const [fundAum, setFundAum] = useState(0);
  const [strategyAUMs, setStrategyAUMs] = useState([]);
  const [originalStrategyAUMs, setOriginalStrategyAUMs] = useState([]);
  const { accountingData } = useSelector((state) => state?.todos);
  const accountingId = accountingData[0].accountingId;
  const dispatch = useDispatch();

  useEffect(() => {
    if (recordForEdit && recordForEdit?.strategyAUMs && recordForEdit?.aum) {
      const filteredAndSortedAUMs = recordForEdit?.strategyAUMs
        .filter((strategy) => strategy?.strategyAUM >= 10)
        .sort((a, b) => b.strategyAUM - a.strategyAUM);

      setStrategyAUMs(filteredAndSortedAUMs);
      setOriginalStrategyAUMs(filteredAndSortedAUMs.map((s) => s.strategyAUM));
      setFundAum(recordForEdit?.aum?.toFixed(2));
    }
  }, [recordForEdit]);

  //   useEffect(() => {
  //     if (recordForEdit && recordForEdit?.strategyAUMs && recordForEdit?.aum) {
  //       const threshold = 1e-6;

  //       const filteredAndSortedAUMs = recordForEdit?.strategyAUMs
  //         .filter((strategy) => {
  //           const aumValue = strategy?.strategyAUM;
  //           return aumValue > threshold && aumValue.toString()[0] !== "0";
  //         })
  //         .sort((a, b) => b.strategyAUM - a.strategyAUM);

  //       setStrategyAUMs(filteredAndSortedAUMs);
  //       setFundAum(recordForEdit?.aum);
  //     }
  //   }, [recordForEdit]);

  const handleFundAumChange = (e) => {
    let value = e.target.value;

    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
      const newFundAum = parseFloat(value);
      const originalFundAum = parseFloat(recordForEdit?.aum);

      if (
        !isNaN(newFundAum) &&
        !isNaN(originalFundAum) &&
        originalFundAum !== 0
      ) {
        const ratio = newFundAum / originalFundAum;
        const updatedStrategyAUMs = originalStrategyAUMs.map(
          (aum) => aum * ratio
        );
        setStrategyAUMs(
          updatedStrategyAUMs.map((aum, index) => ({
            ...strategyAUMs[index],
            strategyAUM: aum,
          }))
        );
      }

      setFundAum(value);
    }
  };

  const handleSave = () => {
    const updatedData = {
      action: "update_accounting_aum",
      data: {
        accountingId: recordForEdit?.accountingId,
        fundId: recordForEdit?.fund,
        fundAUM: Number(fundAum),
        strategyAUMs: strategyAUMs.reduce((acc, strategy) => {
          acc[strategy.strategyId] = strategy.strategyAUM;
          return acc;
        }, {}),
      },
    };

    window.clientSocks.send(JSON.stringify(updatedData));
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg(""));
    setOpenPopup(false);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        width: "100%",
        height: "auto",
        padding: "2px",
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="body1">Fund AUM:</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Fund AUM"
            variant="standard"
            value={fundAum}
            onChange={handleFundAumChange}
            fullWidth
            type="text"
          />
        </Grid>
      </Grid>

      <Typography
        variant="body2"
        gutterBottom
        sx={{ my: 1, fontSize: "15px", fontWeight: "bold" }}
      >
        Strategy AUMs
      </Typography>
      <Divider sx={{ mb: 1 }} />

      <Grid container spacing={1}>
        {strategyAUMs?.map((strategy, index) => (
          <Grid item xs={12} key={index}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Typography variant="body1" noWrap>
                  {strategy.strategyName}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ textAlign: "right" }}>
                  {calculateAndFormatNoAbs(strategy?.strategyAUM.toFixed(2))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 5 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ width: "100px" }}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default EditAccounting;
