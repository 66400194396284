import React, { useEffect, useState } from "react";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";
import ArchivedReportsLayout from "../component/Layouts/ArchivedReportsLayout";
import ArchivedReportDetailsLayout from "../component/Layouts/ArchivedReportDetailsLayout";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ArchivedReports = () => {
  const { id } = useParams();
  const { reports } = useSelector((state) => state.todos);
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    if (reports) {
      const findReport = reports.find((i) => i.reportId === id);
      const reportName = findReport?.name;
      const title = `${reportName ? reportName + "'s " : ""}EOD REPORT`;
      setPageTitle(title);
    }
  }, [reports, id]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Topbar title={pageTitle} />

      <ArchivedReportDetailsLayout />
    </>
  );
};

export default ArchivedReports;
