import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box, Select, MenuItem } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TABLE_HEAD_FUND_REBALANCE_EXISTING } from "../../../../../Methods";
import Approved from "./components/Approved";
import Adjusted from "./components/Adjusted";
import Rejected from "./components/Rejected";
import { useDispatch, useSelector } from "react-redux";
import { incrementActiveRequests, updateLoading, updateLoadingMsg } from "../../../../../../Redux/Reducers/todoReducers";
const FundRebalanceStep5 = ({ setCurrentStep, onPrevious }) => {
  const [approved, setApproved] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [partial, setPartial] = useState([]);
  const [missingLocates, setMissingLocates] = useState([]);
  const dispatch = useDispatch();

  const { AllTrades, rebalancerFund } = useSelector((state) => state.todos);

  useEffect(() => {
    if (AllTrades && Array.isArray(AllTrades?.realOrders)) {
      const realOrders = AllTrades?.realOrders;
      const approvedData = [];
      const rejectedData = [];
      const partialData = [];
      const realOrdersFiltered = realOrders?.filter(
        (trade) => trade?.position !== 0
      );
      realOrdersFiltered?.forEach((trade) => {
        const { ticker, shareCount, availableLocates } = trade;
        if (ticker?.symbol === "qcom") {
        }

        if (availableLocates > 0 && availableLocates >= shareCount) {
          approvedData.push({ ticker: ticker?.symbol, shareCount });
        } else if (availableLocates === 0) {
          setMissingLocates((prevMissingLocates) => [
            ...prevMissingLocates,
            { ticker: ticker, missing: shareCount },
          ]);

          rejectedData.push({ ticker: ticker?.symbol, shareCount });
        } else if (availableLocates < shareCount && shareCount > 0) {
          const missing = shareCount - availableLocates;
          setMissingLocates((prevMissingLocates) => [
            ...prevMissingLocates,
            { ticker: ticker, missing: missing },
          ]);
          partialData.push({ ticker: ticker?.symbol, shareCount: missing });
          approvedData.push({
            ticker: ticker?.symbol,
            shareCount: availableLocates,
          });
        }
      });
      setApproved(approvedData);
      setRejected(rejectedData);
      setPartial(partialData);
    }
  }, [AllTrades]);

  const onNext = () => {
    const socketRequest = {
      action: "rebalancer_locates",
      data: {
        processId: AllTrades.processId,
        missingLocates: missingLocates,
      },
    };

    window.clientSocks.send(JSON.stringify(socketRequest));

    const socketRequest2 = {
      action: "rebalancer_summary",
      data: {
        processId: AllTrades.processId,
        fundId: rebalancerFund,
      },
    };

    window.clientSocks.send(JSON.stringify(socketRequest2));
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Generating Summary"));

    setCurrentStep((prev) => {
      const nextStep = prev + 1;
      return nextStep;
    });
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                color="grey"
                startIcon={<ArrowBackIcon />}
                onClick={onPrevious}
                sx={{ width: "150px", height: "40px" }}
              >
                BACK
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={onNext}
                sx={{ width: "150px", height: "40px" }}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "70%",
              justifyContent: "space-between",
            }}
          >
            <Approved
              approved={approved}
              tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING}
            />

            <Rejected
              rejected={rejected}
              tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING}
            />

            <Adjusted
              adjusted={partial}
              tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FundRebalanceStep5;
