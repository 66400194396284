import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Button,
  Box,
  Alert,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TABLE_HEAD_FUND_REBALANCE_BLACKLIST,
  findFundByName,
  findStrategy,
} from "../../../../../Methods";
import LocatesNotFoundPopup from "../../../../../Popup/LocatesNotFoundPopup";
import Blacklisted from "./components/Blacklisted";
import { useDispatch, useSelector } from "react-redux";
import { incrementActiveRequests, updateLoading, updateLoadingMsg } from "../../../../../../Redux/Reducers/todoReducers";

const FundRebalanceStep4 = ({
  sleeve,
  setCurrentStep,
  onPrevious,
  ignoredData,
  selectedFund,
}) => {
  const dispatch = useDispatch();
  const { blacklistedTickers, strategies, funds } = useSelector(
    (state) => state.todos
  );
  const [popupOpen, setPopupOpen] = useState(false);
  const [blacklist, setBlacklistData] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState("all");
  const [strategyData, setStrategyData] = useState(null);
  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    if (sleeve) {
      const strategyIds = Object.keys(blacklistedTickers);
      const defaultStrategyId = "all";
      setSelectedStrategy(defaultStrategyId);
      if (defaultStrategyId === "all") {
        const allStrategiesData = Object.entries(blacklistedTickers)
          .reduce(
            (acc, [strategyId, tickers]) =>
              acc.concat(
                tickers.map((ticker) => ({
                  ...ticker,
                  strategyId,
                }))
              ),
            []
          )
          .sort((a, b) =>
            a.ticker.symbol
              .toLowerCase()
              .localeCompare(b.ticker.symbol.toLowerCase())
          );
        setStrategyData(allStrategiesData);
      } else if (blacklistedTickers[defaultStrategyId]) {
        setStrategyData(
          blacklistedTickers[defaultStrategyId].map((item) => ({
            ...item,
            strategyId: defaultStrategyId,
          }))
        );
      }
    } else {
      setStrategyData(blacklistedTickers);
    }
  }, [sleeve, blacklistedTickers]);

  useEffect(() => {
    if (strategyData) {
      const blacklists = strategyData;
      if (blacklists) {
        const updatedList = blacklists?.map((item) => ({
          reason: item?.reason,
          ticker: item?.ticker?.symbol,
          strategy: item?.strategyId,
        }));

        setBlacklistData(updatedList);
      }
    }
  }, [strategyData]);

  const handleStrategyChange = (e) => {
    const newStrategyId = e.target.value;
    setSelectedStrategy(newStrategyId);
    if (newStrategyId === "all") {
      const allStrategiesData = Object.entries(blacklistedTickers)
        .reduce(
          (acc, [strategyId, tickers]) =>
            acc.concat(
              tickers.map((ticker) => ({
                ...ticker,
                strategyId,
              }))
            ),
          []
        )
        .sort((a, b) =>
          a.ticker.symbol
            .toLowerCase()
            .localeCompare(b.ticker.symbol.toLowerCase())
        );
      setStrategyData(allStrategiesData);
    } else {
      const strategyDataWithId = blacklistedTickers[newStrategyId].map(
        (ticker) => ({
          ...ticker,
          strategyId: newStrategyId,
        })
      );
      setStrategyData(strategyDataWithId);
    }
  };
  const onNext = () => {
    const strategyIds = Object.keys(blacklistedTickers);

    const transformedIgnoredData = strategyIds.flatMap((strategyId) => {
      const ticker = blacklistedTickers[strategyId];

      return ticker.map((tickerObj) => ({
        symbol: tickerObj?.ticker?.symbol,
        investment: tickerObj?.ticker?.investment,
        exchange: tickerObj?.ticker?.exchange,
        currency: tickerObj?.ticker?.currency,
      }));
    });
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Creating Rebalancer Items"));
    const socketRequest = {
      action: "create_rebalancer_items",
      data: {
        fundId: findFundByName(selectedFund, funds),
        rebalancerType: 0,
        strategies: strategyIds,
        ignoredTickers: ignoredData,
        blacklistedTickers: transformedIgnoredData,
      },
    };

    window.clientSocks.send(JSON.stringify(socketRequest));
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "35%",
              }}
            >
              <Box
                sx={{
                  marginBottom: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: "bold !important", fontSize: "20px !important" }}>
                  Blacklisted Securities
                </Typography>
                {sleeve && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Select
                      value={selectedStrategy}
                      onChange={handleStrategyChange}
                      sx={{
                        width: "200px",
                        border: "none",
                        fontSize: "20px",
                        fontWeight: "500",
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {Object.keys(blacklistedTickers)?.map((strategyId) => (
                        <MenuItem key={strategyId} value={strategyId}>
                          {strategyId === "all"
                            ? "All"
                            : findStrategy(strategyId, strategies)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>
            </div>
            {/* {blacklist && blacklist.length > 0 ? ( */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
              }}
            >
              {/* <Button
                  variant="contained"
                  color="grey"
                  startIcon={<ArrowBackIcon />}
                  onClick={onPrevious}
                  sx={{ width: "150px", height: "40px" }}
                >
                  BACK
                </Button> */}

              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={onNext}
                sx={{ width: "150px", height: "40px" }}
              >
                Next
              </Button>
            </Box>
            {/* ) : null} */}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "34.5%",
              justifyContent: "space-between",
            }}
          >
            <Alert severity="info">
              {" "}
              The securities that appear below, will not be counted in final
              rebalancer trades
            </Alert>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "35%",
              justifyContent: "space-between",
            }}
          >
            {blacklist && TABLE_HEAD_FUND_REBALANCE_BLACKLIST ? (
              <Blacklisted
                blacklist={blacklist}
                tableHeader={TABLE_HEAD_FUND_REBALANCE_BLACKLIST}
              />
            ) : (
              <p>No data available</p>
            )}
          </div>
        </Grid>
      </Grid>

      <LocatesNotFoundPopup open={popupOpen} onClose={handleClosePopup} />
    </Container>
  );
};

export default FundRebalanceStep4;
