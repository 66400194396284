import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  Button,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";

const ReportsSchedular = ({ openPopup, setOpenPopup }) => {
  const theme = useTheme();
  const [scheduleType, setScheduleType] = useState("monthly");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  useEffect(() => {
    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentTime.getSeconds()).padStart(2, "0");
    setSelectedTime(`${hours}:${minutes}:${seconds}`);
  }, []);

  const handleScheduleTypeChange = (event) => {
    setScheduleType(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleSchedule = () => {
    console.log("Scheduled report:", {
      scheduleType,
      selectedDate,
      selectedTime,
    });
    setOpenPopup(false);
  };

  const handleCancel = () => {
    setOpenPopup(false);
  };

  return (
    <>
      <Dialog open={openPopup} onClose={handleCancel}>
        <DialogTitle>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Schedule Report
          </Typography>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCancel}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <RadioGroup
            value={scheduleType}
            onChange={handleScheduleTypeChange}
            aria-label="schedule-type"
            name="schedule-type"
            sx={{ marginBottom: 2 }}
          >
            <FormControlLabel value="daily" control={<Radio />} label="Daily" />
            <FormControlLabel
              value="weekly"
              control={<Radio />}
              label="Weekly"
            />
            <FormControlLabel
              value="monthly"
              control={<Radio />}
              label="Monthly"
            />
          </RadioGroup>

          <TextField
            label="Select Date"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Select Time"
            type="time"
            value={selectedTime}
            onChange={handleTimeChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccessTimeIcon />
                </InputAdornment>
              ),
            }}
            sx={{ marginBottom: 2 }}
          />
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "15px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "150px",
              }}
              onClick={handleSchedule}
            >
              SCHEDULE
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "150px",
                background: "#B2BEB5",
                color: "#000",
                "&:hover": { background: "#808080" },
              }}
              onClick={handleCancel}
            >
              CLOSE
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReportsSchedular;
