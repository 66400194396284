import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Fab from '@mui/material/Fab';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useSelector, useDispatch } from "react-redux";
import { formatTimeAgo } from '../../utils/helpers';
import { requestMarketData } from "../../Redux/Actions/todoActions";
import {
    getLoading,
    updateLoading,
    updateLoadingMsg,
} from "../../Redux/Reducers/todoReducers";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Button,
    Grid,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress
} from "@mui/material";

const Drawer = () => {
    const [open, setOpen] = React.useState(false);
    const loading = useSelector(getLoading);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogData, setDialogData] = React.useState([]);
    const [availableDialogOpen, setAvailableDialogOpen] = React.useState(false);
    const [expiredDialogOpen, setExpiredDialogOpen] = React.useState(false);
    const [availableDialogData, setAvailableDialogData] = React.useState([]);
    const [expiredDialogData, setExpiredDialogData] = React.useState([]);
    const dispatch = useDispatch();
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    };

    const threshold = localStorage.getItem("threshold") || 60;

    const { livePrices, markets, lots } = useSelector((state) => state?.todos);

    // Calculate the counts
    const currentTime = Date.now() / 1000; // Convert to seconds
    const thresholdInSeconds = threshold * 60; // Convert threshold to seconds
    const availableCount = livePrices.filter(item => item.price !== 1 && item.yestPrice !== 1 && (currentTime - item.expiration) < thresholdInSeconds).length;
    const unavailableData = livePrices.filter(item => item.price === 1 && item.yestPice === 1);
    const unavailableCount = unavailableData.length;
    const expiredData = livePrices.filter(item =>
        (currentTime - item.expiration) > thresholdInSeconds && !(item.price === 1 && item.yestPrice === 1)
    );
    const expiredCount = expiredData.length;

    const handleRowClick = (event, data) => {
        event.stopPropagation(); // Prevent the drawer from closing
        setDialogData(data);
        setDialogOpen(true);
    };

    const handleAvailableRowClick = (event) => {
        event.stopPropagation();
        const nonExpiredAvailableData = livePrices.filter(item =>
            item.price !== 1 &&
            item.yestPrice !== 1 &&
            (currentTime - item.expiration) <= thresholdInSeconds
        );
        setAvailableDialogData(nonExpiredAvailableData);
        setAvailableDialogOpen(true);
    };


    const handleExpiredRowClick = (event) => {
        event.stopPropagation();
        setExpiredDialogData(expiredData);
        setExpiredDialogOpen(true);
    };

    // Helper function to get market name by ID
    const getMarketNameById = (marketId) => {
        const market = markets.find(market => market.marketId === marketId);
        return market ? market.name : 'Unknown Market';
    };

    const handleMarketData = (event) => {
        event.stopPropagation();
        dispatch(
            requestMarketData({ lots: lots, threshold: threshold })
        );
    };


    const MarketDataTable = ({ title, data, open, onClose }) => {
        const [sortConfig, setSortConfig] = React.useState({ key: 'symbol', direction: 'asc' });

        const sortedData = React.useMemo(() => {
            const sortableItems = [...data];
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
            return sortableItems;
        }, [data, sortConfig]);

        const handleSort = (key) => {
            let direction = 'asc';
            if (sortConfig.key === key && sortConfig.direction === 'asc') {
                direction = 'desc';
            }
            setSortConfig({ key, direction });
        };

        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: 600, fontSize: "20px" }}>{title}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper} sx={{ borderBottom: "0px", boxShadow: "none" }}>
                        <Table>
                            <TableHead>
                                <TableRow>

                                    <TableCell onClick={() => handleSort('symbol')} sx={{ width: "10%", paddingTop: "0px", paddingBottom: "0px", cursor: 'pointer' }}>Ticker</TableCell>
                                    <TableCell onClick={() => handleSort('yestPrice')} sx={{ width: "5%", paddingTop: "0px", paddingBottom: "0px", cursor: 'pointer' }}>Last</TableCell>
                                    <TableCell onClick={() => handleSort('price')} sx={{ width: "10%", paddingTop: "0px", paddingBottom: "0px", cursor: 'pointer' }}>Current</TableCell>
                                    <TableCell onClick={() => handleSort('market')} sx={{ width: "20%", paddingTop: "0px", paddingBottom: "0px", cursor: 'pointer' }}>Market</TableCell>
                                    <TableCell onClick={() => handleSort('expiration')} sx={{ width: "60%", paddingTop: "0px", paddingBottom: "0px", cursor: 'pointer' }}>Fetched Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.map((item, index) => (
                                    <TableRow key={index} >
                                        <TableCell sx={{ maxWidth: "50px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "10%", paddingTop: "0px", paddingBottom: "0px" }}>{item.symbol}</TableCell>
                                        <TableCell
                                            sx={{
                                                width: "5%",
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                                textAlign: "right",
                                                paddingRight: "8px"
                                            }}
                                        >
                                            {item.yestPrice.toFixed(2)}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: "10%",
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                                textAlign: "right",
                                                paddingRight: "8px"
                                            }}
                                        >
                                            {item.price.toFixed(2)}
                                        </TableCell>
                                        <TableCell sx={{ width: "20%", paddingTop: "0px", paddingBottom: "0px" }}>{getMarketNameById(item?.market)}</TableCell>
                                        <TableCell sx={{ minWidth: "120px", width: "60%", paddingTop: "0px", paddingBottom: "0px", textAlign: "right", }}>
                                            {formatTimeAgo(item.expiration).msg}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        );
    };

    return (
        <div>
            <SwipeableDrawer
                anchor="right"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <div
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    style={{ display: "flex", width: "300px", flexDirection: "column" }}
                >
                    <Typography variant='h4' sx={{ textAlign: "center", fontWeight: 600, fontSize: "20px", marginTop: 5 }}>
                        Market Data
                    </Typography>

                    <Table sx={{ mt: 2 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ py: 0.5 }}>
                                    Threshold
                                </TableCell>
                                <TableCell align="right" sx={{ py: 0.5 }}>{threshold} mins</TableCell>
                            </TableRow>
                            <TableRow onClick={handleAvailableRowClick} style={{ cursor: 'pointer' }}>
                                <TableCell component="th" scope="row" sx={{ py: 0.5 }}>
                                    Available Market Data
                                </TableCell>
                                <TableCell align="right" sx={{ py: 0.5 }}>{availableCount} Tickers</TableCell>
                            </TableRow>
                            <TableRow onClick={(event) => handleRowClick(event, unavailableData)} style={{ cursor: 'pointer' }}>
                                <TableCell component="th" scope="row" sx={{ py: 0.5 }}>
                                    Unavailable Market Data
                                </TableCell>
                                <TableCell align="right" sx={{ py: 0.5 }}>{unavailableCount} Tickers</TableCell>
                            </TableRow>
                            <TableRow onClick={handleExpiredRowClick} style={{ cursor: 'pointer' }}>
                                <TableCell component="th" scope="row" sx={{ py: 0.5 }}>
                                    Expired Market Data
                                </TableCell>
                                <TableCell align="right" sx={{ py: 0.5 }}>{expiredCount} Tickers</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "90%", alignSelf: "center", flexDirection: "column", marginTop: 10 }}>
                        <Button
                            onClick={handleMarketData}
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1, width: "100%" }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : "Fetch from Bloomberg"}
                        </Button>
                        <Button
                            onClick={() => { /* Fetch from FactSet logic */ }}
                            variant="contained"
                            color="info"
                            sx={{ mt: 1, width: "100%" }}
                        >
                            Fetch from FactSet
                        </Button>
                    </div>
                </div>
            </SwipeableDrawer>

            <MarketDataTable
                title="Unavailable Market Data"
                data={dialogData}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            />

            <MarketDataTable
                title="Available Market Data"
                data={availableDialogData}
                open={availableDialogOpen}
                onClose={() => setAvailableDialogOpen(false)}
            />

            <MarketDataTable
                title="Expired Market Data"
                data={expiredDialogData}
                open={expiredDialogOpen}
                onClose={() => setExpiredDialogOpen(false)}
            />
            <Fab
                color="black"
                aria-label="add"
                onClick={() => setOpen(!open)}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    backgroundColor: "#000",
                    '&:hover': {
                        backgroundColor: "#000"
                    }
                }}
            >
                <QueryStatsIcon />
            </Fab>
        </div>
    );
}

export default Drawer;
