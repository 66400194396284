import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
  TableCell,
  MenuItem,
  Select,
} from "@mui/material";
import {
  findBrokerAbb,
  findStrategy,
  formatValue,
  Tablecell,
} from "../../../Methods";
import { useSelector } from "react-redux";

const RebalanceDetailedInfo = ({
  sleeve,
  setExistingArray = () => {},
  setNewArray = () => {},
  setOrders = () => {},
  sleeveDataStageOne,
  last,
}) => {
  const theme = useTheme();
  const { data, strategies } = useSelector((state) => state.todos);
  const [selectedStrategy, setSelectedStrategy] = useState("");
  const [summaryData, setSummaryData] = useState([]);
  const [preferenceData, setPreferenceData] = useState([]);
  const [strategyData, setStrategyData] = useState(null);

  useEffect(() => {
    if (sleeve) {
      const strategyIds = Object.keys(sleeveDataStageOne);
      const defaultStrategyId = strategyIds[0];
      setSelectedStrategy(defaultStrategyId || "");
      setStrategyData(sleeveDataStageOne[defaultStrategyId]);
    } else {
      setStrategyData(sleeveDataStageOne);
    }
  }, [sleeve, sleeveDataStageOne]);

  useEffect(() => {
    if (strategyData) {
      const { rebalancer } = strategyData;
      setSummaryData([
        {
          name: "AUM",
          existing: formatValue(rebalancer.existingAUM),
          new: formatValue(rebalancer.newAUM),
        },
        {
          name: "Total for Long",
          existing: `${rebalancer.existingTotalLong?.toFixed(2)}%`,
          new: `${rebalancer.newTotalLong?.toFixed(2)}%`,
        },
        {
          name: "Total for Short",
          existing: `${rebalancer.existingTotalShort?.toFixed(2)}%`,
          new: `${rebalancer.newTotalShort?.toFixed(2)}%`,
        },
      ]);

      setPreferenceData([
        {
          name: "Custodian Preference",
          existing: "",
          new: rebalancer.preferredCustodian || "",
        },
        {
          name: "Broker Preference",
          existing: "",
          new: findBrokerAbb(rebalancer.preferredBroker, data) || "",
        },
        { name: "Order Type", existing: "", new: "Limit" },
        { name: "Slippage Limit %", existing: "", new: "0.40%" },
        {
          name: "Ignore orders below",
          existing: "",
          new: "0.50 Basic Points",
        },
        { name: "Post to OMS?", existing: "", new: "TRUE" },
      ]);

      const positions = strategyData.rebalancerItems;

      if (setExistingArray && setNewArray) {
        const updatedExistingArray = positions?.map((item) => ({
          ticker: item?.ticker?.symbol,
          weight: item?.currentPosition?.weight,
          shareCount: item?.currentPosition?.shareCount,
          blacklisted: item?.blacklisted,
          signal: item?.signalWeight,
        }));
        const updatedNewArray = positions?.map((item) => ({
          ticker: item?.ticker?.symbol,
          weight: item?.requiredPosition?.weight,
          newWeight: item?.currentPosition?.weight,
          shareCount: item?.requiredPosition?.shareCount,
          blacklisted: item?.blacklisted,
          signal: item?.signalWeight,
        }));

        setExistingArray(updatedExistingArray);
        setNewArray(updatedNewArray);
      }

      if (setOrders && last) {
        const orders = strategyData?.orders;
        setOrders(orders);
      }
    }
  }, [strategyData]);

  const handleStrategyChange = (e) => {
    const newStrategyId = e.target.value;
    setSelectedStrategy(newStrategyId);
    setStrategyData(sleeveDataStageOne[newStrategyId]);
  };

  return (
    <Box
      sx={{
        border: `100px ${theme.palette.lightGrey.tableHeader}`,
        borderRadius: "4px",
        width: "60%",
        marginRight: 1,
      }}
    >
      <Box
        sx={{
          marginBottom: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontWeight: "bold !important", fontSize: "20px !important" }}>
          Review Data
        </Typography>
        {sleeve && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Select
              value={selectedStrategy}
              onChange={handleStrategyChange}
              sx={{
                width: "200px",
                border: "none",
                fontSize: "20px",
                fontWeight: "500",
                "& fieldset": { border: "none" },
                "&:hover fieldset": { border: "none" },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {Object.keys(sleeveDataStageOne)?.map((strategyId) => (
                <MenuItem key={strategyId} value={strategyId}>
                  {findStrategy(strategyId, strategies)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>

      <Table
        size="small"
        stickyHeader
        aria-label="a dense table"
        sx={{
          borderCollapse: "collapse",
          border: "1px solid #7C7C7C",
          marginTop: "-1px",
          py: 2,
          paddingRight: 2,
        }}
      >
        <TableBody>
          {/* Summary Data */}
          <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
            <TableCell
              align="left"
              colSpan={2}
              sx={{
                fontSize: "12px",
                border: "none",
                paddingLeft: "12px",
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              Summary
            </TableCell>
            <Tablecell
              align="center"
              sx={{
                fontSize: "12px",
                border: "none",
                fontWeight: "bold",
                paddingBottom: "10px",
                paddingTop: "5px",
              }}
            >
              Existing
            </Tablecell>
            <Tablecell
              align="center"
              sx={{
                fontSize: "12px",
                border: "none",
                fontWeight: "bold",
                paddingBottom: "10px",
                paddingTop: "5px",
              }}
            >
              New
            </Tablecell>
          </TableRow>

          {summaryData?.map((row) => (
            <TableRow
              sx={{
                "&:hover": {
                  background: "none",
                },
              }}
              key={row.name}
            >
              <TableCell
                align="left"
                colSpan={2}
                sx={{
                  fontSize: "12px",
                  p: "0px",
                  paddingBottom: "2px",
                  paddingLeft: "15px",
                  border: "none",
                }}
              >
                {row?.name}
              </TableCell>
              <Tablecell
                align="center"
                sx={{
                  fontSize: "12px",
                  border: "none",
                  p: "0px",
                  paddingBottom: "2px",
                }}
              >
                {row?.existing}
              </Tablecell>
              <Tablecell
                align="center"
                sx={{
                  fontSize: "12px",
                  border: "none",
                  p: "0px",
                  paddingBottom: "2px",
                }}
              >
                {row?.new}
              </Tablecell>
            </TableRow>
          ))}
        </TableBody>

        <TableBody>
          {/* Preferences Data */}
          <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
            <TableCell
              align="left"
              colSpan={2}
              sx={{
                fontSize: "12px",
                border: "none",
                paddingLeft: "12px",
                fontWeight: "bold",
                paddingTop: "20px",
                paddingBottom: "10px",
              }}
            >
              Preferences
            </TableCell>
          </TableRow>

          {preferenceData?.map((row) => (
            <TableRow
              sx={{
                "&:hover": {
                  background: "none",
                },
              }}
              key={row.name}
            >
              <TableCell
                align="left"
                colSpan={2}
                sx={{
                  fontSize: "12px",
                  border: "none",
                  p: "0px",
                  paddingLeft: "15px",
                  paddingBottom: "2px",
                }}
              >
                {row?.name}
              </TableCell>
              <Tablecell
                align="center"
                sx={{
                  fontSize: "12px",
                  border: "none",
                  p: "0px",
                  paddingBottom: "2px",
                }}
              >
                {row?.new}
              </Tablecell>
              <Tablecell
                align="center"
                sx={{
                  fontSize: "12px",
                  border: "none",
                  p: "0px",
                  paddingBottom: "2px",
                }}
              >
                {row?.existing}
              </Tablecell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default RebalanceDetailedInfo;
