import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  useTheme,
  IconButton,
} from "@mui/material";
import {
  calculateAndFormatNoAbs,
  checkTestKeys,
  findBrokerName,
  findStrategy,
  hashAction,
  hashPosition,
  TABLE_CROSS_TRADE_ACTIVITY as tableHeader1,
  TABLE_CROSS_TRADE_RESULTING as tableHeader2,
} from "../../../component/Methods";
import Popup from "../../../component/Popup";
import AddTradeDetails from "../../../Pages/AddTradeDetails";
import EditTradeDetails from "../../../Pages/EditTradeDetails";
import PopUp from "../PopUp";
import CloseIcon from "@mui/icons-material/Close";
import OutputOrders from "./Tables/OutputOrders";
import InputOrders from "./Tables/InputOrders";
import { useSelector } from "react-redux";

const CrossingTrade = (props) => {
  const theme = useTheme();

  const {
    title,
    openCrossPopup,
    seOpenCrossPopup,
    TradeForEdit,
    setTradeForEdit,
    processedTrades,
    strategies,
  } = props;
  const { funds, data } = useSelector((state) => state.todos);
  const [openPopup, setOpenPopup] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [resultingData, setResultingData] = useState([]);
  useEffect(() => {
    if (processedTrades) {
      if (processedTrades.inputOrders?.length > 0) {
        const updatedInputOrders = processedTrades?.inputOrders?.map(
          (order) => {
            const distributionData = order?.distribution
              ? Object.keys(order?.distribution).map((key) => {
                  const [account, fund] = key.split("#");
                  return { account: account, fund: fund };
                })
              : [];

            return {
              ticker: order?.ticker?.symbol,
              strategy: findStrategy(order?.strategy, strategies),
              position:
                hashAction(order?.action) + hashPosition(order?.position),
              shareCount: calculateAndFormatNoAbs(order?.shareCount),
              broker: order?.broker,
              tradeObj: order,
              custodian: distributionData[0]?.account || "",
              fund: distributionData[0]?.fund || "",
              accounts: checkTestKeys(distributionData[0]?.fund, funds) || [],
            };
          }
        );
        setActivityData(updatedInputOrders);
      } else {
        setActivityData([]);
      }

      if (processedTrades.outputOrders?.length > 0) {
        const updatedOutputOrders = processedTrades?.outputOrders?.map(
          (order) => {
            const distributionData = order?.distribution
              ? Object.keys(order?.distribution).map((key) => {
                  const [account, fund] = key.split("#");
                  return { account: account, fund: fund };
                })
              : [];

            return {
              ticker: order?.ticker?.symbol,
              strategy: findStrategy(order?.strategy, strategies),
              activity:
                hashAction(order?.action) + hashPosition(order?.position),
              shares: calculateAndFormatNoAbs(order?.shareCount),
              tradeObj: order,
              custodian: order?.custodian || "",
              broker: findBrokerName(order?.broker, data)?.toLowerCase(),
            };
          }
        );

        setResultingData(updatedOutputOrders);
      } else {
        setResultingData([]);
      }
    } else {
      setActivityData([]);
      setResultingData([]);
    }
  }, [processedTrades]);

  const handleClose = () => {
    seOpenCrossPopup(false);
    setActivityData([]);
    setResultingData([]);
  };

  return (
    <Dialog
      open={openCrossPopup}
      fullWidth
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "1000px",
          height: "100vh",
        },
      }}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <InputOrders input={activityData} tableHeader={tableHeader1} />

        <Divider
          sx={{ margin: "20px 0", height: "4px", backgroundColor: "grey" }}
        />

        <OutputOrders output={resultingData} tableHeader={tableHeader2} />
      </DialogContent>
      <PopUp
        title="Trade Entry Form"
        openPopup={openAddPopup}
        onClose={handleClose}
      >
        <AddTradeDetails setOpenAddPopup={setOpenAddPopup} />
      </PopUp>
      <Popup
        title="Edit Trade Entry"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditTradeDetails
          setOpenPopup={setOpenPopup}
          TradeForEdit={TradeForEdit}
          setTradeForEdit={setTradeForEdit}
        />
      </Popup>
    </Dialog>
  );
};

export default CrossingTrade;
