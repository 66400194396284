import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  TableCell,
  TextField,
  Typography,
  Box,
  Checkbox,
  NativeSelect,
  IconButton,
  Card,
  Container,
  TableContainer,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
  TABLE_HEAD_FUNDALLOCATOR as TABLE_HEAD,
  Tablecell,
  applySortFilter,
  calculateAndFormatNoAbs,
  getComparator,
  twoDecimal,
} from "../../../Methods";
import ListHead from "../../../ListHead/AllocatorList";

const TargetAllocation = ({
  custodians,
  sortedData,
  matchedAllocations,
  setMatchedAllocations,
  updateTotals,
  fundAum,
  setPieDataTarget,
  editableValue,
  editableData,
  setEditableData,
}) => {
  const theme = useTheme();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("allocation");
  const [hasChanges, setHasChanges] = useState(false);
  const changeSourceRef = useRef(null);

  useEffect(() => {
    const filteredUsers = applySortFilter(
      matchedAllocations,
      getComparator(order, orderBy)
    );
    setEditableData(filteredUsers);
  }, [order, orderBy, matchedAllocations]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEditRow = (index, field, value, source) => {
    if (changeSourceRef.current === source) {
      return;
    }
    changeSourceRef.current = source;

    let targetValue = 0;
    if (typeof value == "string") {
      targetValue = parseFloat(value.replace(/,/g, "")) || 0;
    } else if (typeof value === "number") {
      targetValue = value;
    }

    setEditableData((prevData) => {
      if (!prevData) return prevData;

      const updatedData = [...prevData];

      if (field === "allocation") {
        const totalAllocationValue = (targetValue / fundAum) * 100;
        updatedData[index] = {
          ...updatedData[index],
          [field]: value,
          totalAllocation: twoDecimal(totalAllocationValue),
        };
      } else if (field === "totalAllocation") {
        const newAllocationValue =
          (parseFloat(editableValue) * parseFloat(targetValue)) / 100;
        updatedData[index] = {
          ...updatedData[index],
          [field]: value,
          allocation: twoDecimal(newAllocationValue),
        };
      } else {
        updatedData[index] = { ...updatedData[index], [field]: value };
      }

      setHasChanges(true);

      changeSourceRef.current = null;

      return updatedData;
    });
  };

  const handleDoneEditing = () => {
    setMatchedAllocations((prevAllocations) => {
      const updatedAllocations = [...prevAllocations];
      updatedAllocations.forEach((allocation, index) => {
        updatedAllocations[index] = {
          ...updatedAllocations[index],
          ...editableData[index],
        };
      });

      updateTotals(updatedAllocations);

      const pieTarget = updatedAllocations.map((strategy) => ({
        name: strategy.name,
        value: Number(strategy.totalAllocation) || 0,
        color: strategy?.color,
      }));

      setPieDataTarget(pieTarget);
      setHasChanges(false);
      return updatedAllocations;
    });
  };

  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "100%",
          height: "120px",
          marginRight: 1,
        }}
      >
        <Card>
          {hasChanges && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                height:"50px"
              }}
            >
              <Button onClick={handleDoneEditing}>Done Editing</Button>
            </Box>
          )}

          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              height: "max-content",
            }}
          >
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {editableData &&
                editableData?.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      sx={{
                        "&:hover": {
                          background: theme.palette.grey[200],
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Tablecell
                        align="left"
                        sx={{
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "5px",
                          }}
                        >
                          <span>{row?.name}</span>
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              background: row?.color,
                            }}
                          ></div>{" "}
                        </div>
                      </Tablecell>
                      <Tablecell
                        align="right"
                        sx={{
                          paddingRight: "5px",
                        }}
                      >
                        {row?.existingPercent
                          ? twoDecimal(row.existingPercent)
                          : "0.00"}
                        %
                      </Tablecell>

                      <Tablecell
                        align="right"
                        sx={{
                          paddingRight: "10px",
                        }}
                      >
                        {row?.existingAllocation
                          ? calculateAndFormatNoAbs(row?.existingAllocation)
                          : "0"}
                      </Tablecell>
                      <Tablecell
                        align="right"
                        sx={{
                          paddingRight: "5px",
                        }}
                      >
                        {row?.long ? twoDecimal(row.long) : "0.00"}%
                      </Tablecell>
                      <Tablecell
                        align="right"
                        sx={{
                          paddingRight: "5px",
                        }}
                      >
                        {row?.short ? twoDecimal(row.short) : "0.00"}%
                      </Tablecell>
                      <Tablecell
                        align="left"
                        sx={{
                          paddingLeft: "5px",
                          "& .MuiCheckbox-root": { padding: "0px" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "left",
                          }}
                        >
                          <Checkbox
                            defaultChecked
                            checked={row?.ignoreWhenRebalancing}
                            size="small"
                            onChange={(e) =>
                              handleEditRow(
                                index,
                                "ignoreWhenRebalancing",
                                e.target.checked,
                                "ignoreWhenRebalancing"
                              )
                            }
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 10 },
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "12px",
                              marginLeft: "5px",
                              textTransform: "uppercase",
                              border: "none",
                            }}
                          >
                            {String(row?.ignoreWhenRebalancing)}
                          </Typography>
                        </Box>
                      </Tablecell>
                      <Tablecell
                        align="right"
                        sx={{
                          paddingRight: "5px",
                        }}
                      >
                        <TextField
                          value={row?.allocation}
                          onChange={(e) => {
                            const value = e.target.value;

                            const formattedValue =
                              value.match(/^\d*(\.\d{0,2})?/);

                            if (formattedValue && !isNaN(formattedValue[0])) {
                              handleEditRow(
                                index,
                                "allocation",
                                formattedValue[0],
                                "allocation"
                              );
                            }
                          }}
                          size="small"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              fontSize: "12px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            },
                          }}
                          inputProps={{
                            step: "any",
                            style: {
                              textAlign: "right",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            },
                          }}
                        />
                      </Tablecell>
                      <Tablecell
                        align="right"
                        sx={{
                          paddingRight: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <TextField
                              value={row?.totalAllocation}
                              onChange={(e) => {
                                const value = e.target.value;

                                const formattedValue =
                                  value.match(/^\d*(\.\d{0,2})?/);

                                if (
                                  formattedValue &&
                                  !isNaN(formattedValue[0])
                                ) {
                                  handleEditRow(
                                    index,
                                    "totalAllocation",
                                    formattedValue[0],
                                    "totalAllocation"
                                  );
                                }
                              }}
                              size="small"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  fontSize: "12px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                },
                              }}
                              inputProps={{
                                step: "any",
                                style: {
                                  textAlign: "right",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  MozAppearance: "textfield",
                                  WebkitAppearance: "none",
                                  "&::-webkit-outer-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                  "&::-webkit-inner-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                            <Box
                              sx={{
                                fontSize: "11px",
                                width: "12px",
                                height: "15px",
                                mt: "3px",
                                mr: 1,
                              }}
                            >
                              %
                            </Box>
                          </Box>
                        </Box>
                      </Tablecell>
                      <Tablecell
                        align="right"
                        sx={{
                          paddingRight: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <TextField
                              value={row?.percentLong}
                              onChange={(e) => {
                                const value = e.target.value;

                                const formattedValue =
                                  value.match(/^\d*(\.\d{0,2})?/);

                                if (
                                  formattedValue &&
                                  !isNaN(formattedValue[0])
                                ) {
                                  handleEditRow(
                                    index,
                                    "percentLong",
                                    formattedValue[0],
                                    "percentLong"
                                  );
                                }
                              }}
                              size="small"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  fontSize: "12px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                },
                              }}
                              inputProps={{
                                step: "any",
                                style: {
                                  textAlign: "right",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  MozAppearance: "textfield",
                                  WebkitAppearance: "none",
                                  "&::-webkit-outer-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                  "&::-webkit-inner-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                            <Box
                              sx={{
                                fontSize: "11px",
                                width: "12px",
                                height: "15px",
                                mt: "3px",
                                mr: 1,
                              }}
                            >
                              %
                            </Box>
                          </Box>
                        </Box>
                      </Tablecell>
                      <Tablecell
                        align="tight"
                        sx={{
                          paddingRight: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <TextField
                              value={row?.percentShort}
                              onChange={(e) => {
                                const value = e.target.value;

                                const formattedValue =
                                  value.match(/^\d*(\.\d{0,2})?/);

                                if (
                                  formattedValue &&
                                  !isNaN(formattedValue[0])
                                ) {
                                  handleEditRow(
                                    index,
                                    "percentShort",
                                    formattedValue[0],
                                    "percentShort"
                                  );
                                }
                              }}
                              size="small"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  fontSize: "12px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                },
                              }}
                              inputProps={{
                                step: "any",
                                style: {
                                  textAlign: "right",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  MozAppearance: "textfield",
                                  WebkitAppearance: "none",
                                  "&::-webkit-outer-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                  "&::-webkit-inner-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                            <Box
                              sx={{
                                fontSize: "11px",
                                width: "12px",
                                height: "15px",
                                mt: "3px",
                                mr: 1,
                              }}
                            >
                              %
                            </Box>
                          </Box>
                        </Box>
                      </Tablecell>
                      <Tablecell
                        align="left"
                        sx={{
                          paddingLeft: "5px",
                          "& .MuiNativeSelect-icon": { display: "none" },
                        }}
                      >
                        <NativeSelect
                          value={row.broker}
                          onChange={(e) =>
                            handleEditRow(
                              index,
                              "broker",
                              e.target.value,
                              "broker"
                            )
                          }
                          autoSelect
                          disableUnderline
                          sx={{
                            fontSize: "12px",
                            height: "4px",
                            width: "100%",
                            padding: "0",
                            margin: "0",
                          }}
                          id={`broker-${index}`}
                          name={`broker-${index}`}
                        >
                          {sortedData?.map((val) => (
                            <option key={val.brokerId} value={val.brokerId}>
                              {val.abbreviation}
                            </option>
                          ))}
                        </NativeSelect>
                      </Tablecell>
                      <Tablecell
                        align="left"
                        sx={{
                          paddingLeft: "5px",
                          "& .MuiNativeSelect-icon": { display: "none" },
                        }}
                      >
                        <NativeSelect
                          value={row.account}
                          onChange={(e) =>
                            handleEditRow(
                              index,
                              "account",
                              e.target.value,
                              "account"
                            )
                          }
                          autoSelect
                          disableUnderline
                          sx={{
                            fontSize: "12px",
                            height: "4px",
                            width: "100%",
                            padding: "0",
                            margin: "0",
                          }}
                          id={`custodian-${index}`}
                          name={`custodian-${index}`}
                        >
                          {custodians?.map((val) => (
                            <option key={val.custodian} value={val.name}>
                              {val.name}
                            </option>
                          ))}
                        </NativeSelect>
                      </Tablecell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Card>
      </Box>
    </>
  );
};

export default TargetAllocation;
