import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";
import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import Popup from "../../Popup";

import {
  getComparator,
  TABLE_HEAD_SECURITY_MASTERS as TABLE_HEAD,
  Tablecell,
  applySortFilter,
  hashExchangeEnum,
  hashInvestment,
  hashCurrencyEnum,
} from "../../Methods";
import DeletePopUp from "../../Popup/DeletePopUp";
import EditSecurity from "../EditSecurity";

const SecurityMasterTable = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("ticker_symbol");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForDelete, setrecordForDelete] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setDeleteOpenPopup] = useState(false);
  const { securities, markets } = useSelector((state) => state?.todos);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  useEffect(() => {
    if (
      !securities ||
      securities.length === 0 ||
      !markets ||
      markets.length === 0
    ) {
      setData([]);
      return;
    }

    const updatedSecurities = securities?.map((security) => {
      if (
        !security ||
        !security.markets ||
        Object.keys(security?.markets).length === 0
      ) {
        return { ...security };
      }

      const tickerSymbol = security.ticker?.symbol || "";
      const exchange = security?.ticker?.exchange;
      const investment = security?.ticker?.investment;
      const currency = security?.ticker?.currency;
      const updatedSecurity = {
        ...security,
        tickerSymbol: tickerSymbol,
        exchange: hashExchangeEnum(exchange),
        investment: hashInvestment(investment),
        currency: hashCurrencyEnum(currency),
      };
      const marketSecurity = security?.markets || {};

      Object.keys(marketSecurity).forEach((marketId) => {
        const externalMarket = markets.find(
          (market) => market.marketId === marketId
        );
        if (externalMarket) {
          updatedSecurity[externalMarket?.name?.toLowerCase()] =
            marketSecurity[marketId];
        }
      });

      return updatedSecurity;
    });

    const filteredUsers = applySortFilter(
      updatedSecurities,
      getComparator(order, orderBy),
      filterName
    );
    setData(filteredUsers);
  }, [order, orderBy, filterName, securities, markets]);

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInDeletePopup = (item) => {
    setrecordForDelete(item);
    setDeleteOpenPopup(true);
  };
  const isNotFound = !data.length && !!filterName;

  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setOrderBy={setOrderBy}
            setOrder={setOrder}
            setData={setData}
            data={data}
          />
          <TableContainer style={{ height: "62vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        name,
                        tickerSymbol,
                        cusip,
                        sedol,
                        isin,
                        figi,
                        bloomberg,
                        factset,
                      } = row;

                      return (
                        <TableRow
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{
                            "&:hover": {
                              background: theme.palette.grey[200],
                              cursor: "pointer",
                              ".deleteIcon": {
                                visibility: "visible",
                              },
                            },
                          }}
                        >
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {index + 1 + page * rowsPerPage}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {name ? name : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {tickerSymbol ? tickerSymbol : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {cusip ? cusip : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {sedol ? sedol : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {isin ? isin : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {figi ? figi : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {bloomberg ? bloomberg : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {factset ? factset : "-"}
                          </Tablecell>

                          <Tablecell
                            align="center"
                            sx={{
                              paddingLeft: "5px",
                              width: "150px",
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                fontSize: "9px",
                                fontWeight: "500",
                                visibility: "hidden",
                              }}
                              className="deleteIcon"
                            >
                              <IconButton
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInPopup(row);
                                }}
                              >
                                <EditIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInDeletePopup(row);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Tablecell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <DeletePopUp
        title="Delete a Security Map"
        openDeletePopup={openDeletePopup}
        setDeleteOpenPopup={setDeleteOpenPopup}
        recordForDelete={recordForDelete}
        deleteObj="delete_security_map"
        item="security_map"
      />

      <Popup
        title="Edit Security Map"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditSecurity
          recordForEdit={recordForEdit}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </>
  );
};
export default SecurityMasterTable;
