import React from "react";
import Topbar from "../component/NavComponents/Topbar";
import { Helmet } from "react-helmet";
import ArchivedReportsLayout from "../component/Layouts/ArchivedReportsLayout";

const Report_EOD = () => {
  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <Topbar title="Reports" />
      <ArchivedReportsLayout />
    </>
  );
};
export default Report_EOD;
