import ArchivedReportsTable from "../ArchivedReportsDetails/ArchivedReportsTable";
import Header from "../Header";
const ArchivedReportsLayout = () => {
  return (
    <>
      <Header>
        <ArchivedReportsTable />
      </Header>
    </>
  );
};
export default ArchivedReportsLayout;
