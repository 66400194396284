import ArchivedDetailTable from "../ArchivedReportsDetails/ArchivedDetailTable";
import Header from "../Header";
const ArchivedReportDetailsLayout = () => {
  return (
    <>
      <Header>
        <ArchivedDetailTable />
      </Header>
    </>
  );
};
export default ArchivedReportDetailsLayout;
