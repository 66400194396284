import React from "react";
import TradeBlotters from "../component/Layouts/TradeBlotters";
import Topbar from "../component/NavComponents/Topbar";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";

const TradeBlotter = () => {
  return (
    <>
      <Helmet>
        <title>Trade Blotter</title>
      </Helmet>
      <Topbar title="Trade Blotter" />
      <TradeBlotters />
      <Footer />
    </>
  );
};
export default TradeBlotter;
