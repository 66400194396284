import React from "react";
import Topbar from "../component/NavComponents/Topbar";
import TradeAccounting from "../component/Layouts/TradeAccounting";
import { Helmet } from "react-helmet";
const Accounting = () => {
  return (
    <>
      <Helmet>
        <title>Accounting | Vantage Alpha</title>
      </Helmet>
      <Topbar title="Accounting" />
      <TradeAccounting />
    </>
  );
};
export default Accounting;
