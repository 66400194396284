import { Box } from "@mui/material";
import React from "react";
import { Circles, InfinitySpin, Oval } from "react-loader-spinner";
import theme from "../../Theme";
import { Typography } from "@mui/material";


export const Spinner = (props) => {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(30px)",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          width: "250px",
          height: "250px",
          borderRadius: "5px",
          padding: "20px",
          boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.4)",
        }}
      >
        <Oval


          visible={true}
          secondaryColor="#eee"
          width="100"
          color={theme.palette.primary.main}
          ariaLabel="infinity-spin-loading"
        />
        <Typography variant="h6" sx={{
          marginTop: 4, color: "black", fontSize: "20px", lineHeight: 1,
          textAlign: "center"
        }}>          {props.message || "Please Wait..."}
        </Typography>
      </Box>
    </Box>
  );
};
