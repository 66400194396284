import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Typography,
  FormControl,
  Box,
} from "@mui/material";
import { processData } from "../../Methods";
import CurrentSignal from "./CurrentSignal";
import ModifiedSignal from "./ModifiedSignal";
import SignalTickerData from "./SignalTickerData";
import CurrentList from "./CurrentList";
import { useSelector } from "react-redux";
import { extractTickerAndWeight } from "../../Methods";
import PerformanceList from "./PerformanceList";
import PerformanceSignal from "./PerformanceSignal";
import CachedIcon from "@mui/icons-material/Cached";
import BlacklistSignal from "../../Popup/BlacklistSignal";

const SignalsInputAllocation = () => {
  const [pasted, setpasted] = useState([]);
  const [strategyVal, setstrategyVal] = useState("");
  const [strategyValObj, setstrategyValObj] = useState();
  const { strategies, isStrategist, managers, blacklistSignal } = useSelector(
    (state) => state?.todos
  );
  const [verifyEnabled, setVerifyEnabled] = useState(false);
  const [copiedData, setCopiedData] = useState([]);
  const [verifyStrategy, setVerifyStrategy] = useState(false);
  const [handleVerify, setHandleVerify] = useState(() => () => {});
  const [setSaveChange, setSaveChangeHandler] = useState(() => () => {});
  const [openBlacklistPopup, setBlacklistOpenPopup] = useState(false);
  const blacklist = [{ ticker: { symbol: "DBX" }, reason: "abc" }];

  function strategySelected(id) {
    processData(strategies, id);
    setstrategyValObj(id);
  }

  const strategyList = strategies
    ?.map((i) => i?.name)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

  const pasteFromClipboard = async () => {
    try {
      const entries = await navigator.clipboard.readText();
      let result = [];

      try {
        result = JSON.parse(entries);
        if (
          !Array.isArray(result) ||
          !result.every((obj) => typeof obj === "object")
        ) {
          throw new Error("Invalid format after parsing as JSON");
        } else {
          result.forEach((obj) => {
            if (obj.value !== undefined) {
              obj.value = parseFloat(obj.value).toFixed(2);
            }
          });
        }
      } catch (jsonParseError) {
        entries.split("\n").forEach((entry) => {
          if (entry.trim() !== "") {
            const extracted = extractTickerAndWeight(entry);

            if (extracted) {
              result.push(extracted);
            } else {
              console.error(`Invalid entry format: ${entry}`);
            }
          }
        });
      }

      setpasted(result);

      if (result.length === 0) {
        alert("Error: The copied text does not match the expected format.");
      }
    } catch (err) {
      setpasted([]);
      alert("Error: Cannot paste from clipboard");
    }
  };
  const handleDataChange = () => {
    if (copiedData && copiedData.length > 0 && verifyEnabled) {
      setpasted(copiedData);
      setVerifyEnabled(false);
    }
  };
  console.log("hello",verifyEnabled)
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{
          marginTop: 5,
          marginBottom: 3,
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 900,
                    lineHeight: "32.89px",
                  }}
                >
                  View and enter new signals for selected strategy
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <FormControl sx={{ width: "200px" }}>
                <Autocomplete
                  options={strategyList}
                  value={strategyVal}
                  autoSelect
                  onChange={(e, newValue) => {
                    setstrategyVal(newValue);
                    const strObj = strategies?.find((i) => {
                      return i?.name == newValue;
                    });
                    if (strObj && strObj?.strategyId) {
                      strategySelected(strObj?.strategyId);
                      setVerifyStrategy(true);
                    } else {
                      setstrategyValObj();
                      setVerifyStrategy(false);
                      setpasted([]);
                    }
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Strategy to Manage"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
              {strategyVal ? (
                <Button
                  variant="outlined"
                  style={{
                    marginLeft: "20px",
                    marginTop: "10px",
                    letterSpacing: "0.49px",
                  }}
                  onClick={pasteFromClipboard}
                >
                  PASTE FROM CLIPBOARD
                </Button>
              ) : (
                <></>
              )}
            </div>

            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flex: "0 0 auto",
                  minWidth: "300px",

                  // top: 97, // Adjust as needed
                  // right: 200, // Adjust as needed
                  zIndex: 1000, // Ensure it's above other elements
                }}
              >
                <Button
                  variant="standard"
                  startIcon={<CachedIcon />}
                  // disabled={!verifyEnabled && !verifyStrategy}
                  disabled={!verifyStrategy}
                  onClick={() => {
                    handleVerify();
                    setBlacklistOpenPopup(true);
                  }}
                  sx={{ mr: 2 }}
                >
                  Verify Signals
                </Button>

                <Button
                  variant="contained"
                  startIcon={<CachedIcon />}
                  // disabled={!verifyEnabled}
                  disabled={!verifyStrategy}
                  onClick={() => setSaveChange(true)}
                >
                  SAVE CHANGES
                </Button>
              </Box>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <CurrentSignal
                  strategies={strategies}
                  strategyVal={strategyValObj}
                />
              </div>
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <ModifiedSignal pastedArray={pasted} />
              </div>
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <PerformanceSignal
                  strategies={strategies}
                  strategyValObj={strategyValObj}
                  strategyVal={strategyVal}
                />
              </div>
            </div>
          </Grid>
          <Grid>
            {verifyEnabled ? (
              <Button onClick={handleDataChange}>g</Button>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
                // overflowX: "auto",
                // whiteSpace: "nowrap",
              }}
            >
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <CurrentList
                  strategies={strategies}
                  strategyValObj={strategyValObj}
                  strategyVal={strategyVal}
                />
              </div>

              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <SignalTickerData
                  pastedArray={pasted}
                  setpasted={setpasted}
                  strategyValObj={strategyValObj}
                  setVerifyEnabled={setVerifyEnabled}
                  setCopiedData={setCopiedData}
                  setHandleVerify={setHandleVerify}
                  setSaveChangeHandler={setSaveChangeHandler}
                  blacklist={blacklist}
                  isStrategist={isStrategist}
                />
              </div>
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <PerformanceList
                  strategies={strategies}
                  strategyValObj={strategyValObj}
                  strategyVal={strategyVal}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        {!isStrategist ? (
          <BlacklistSignal
            pastedArray={pasted}
            setpasted={setpasted}
            openBlacklistPopup={openBlacklistPopup}
            setBlacklistOpenPopup={setBlacklistOpenPopup}
            title="Blacklist Signals"
            managers={managers}
            blacklistSignal={blacklistSignal}
          />
        ) : null}
      </Container>
    </>
  );
};

export default SignalsInputAllocation;
