import React, { useRef, useState } from "react";
import {
  Button,
  Avatar,
  Stack,
  TextField,
  Container,
  Typography,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { UserProfileInformation } from "../../Schemas";

const ProfileInfo = () => {
  const { user, userInfo } = useSelector((state) => state?.users);
  const [uploadedImage, setUploadedImage] = useState(null);
  const theme = useTheme();

  const fileInputRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleDeletePic = () => {
    setUploadedImage(null);
  };

  const formik = useFormik({
    initialValues: {
      name: userInfo?.firstName,
      lname: userInfo?.lastName,
      email: userInfo?.email,
      phone: userInfo?.phone,
    },
    validationSchema: UserProfileInformation,
    onSubmit: async ({ values }) => {},
  });
  const doesProfilePicExist = () => {
    const img = new Image();
    img.src = `https://users.precept.cloud/${user?.username?.toLowerCase()}.jpg`;
    return img.complete || img.height > 0;
  };
  const getUserAvatar = () => {
    if (uploadedImage) {
      return (
        <Avatar
          src={uploadedImage}
          alt="user-picture"
          sx={{
            border: `1px solid #272727`,
            cursor: "pointer",
            width: "220px",
            height: "220px",
          }}
        />
      );
    } else {
      const profilePicExists = doesProfilePicExist();

      if (profilePicExists) {
        return (
          <Avatar
            src={`https://users.precept.cloud/${user?.username?.toLowerCase()}.jpg`}
            alt="user-picture"
            sx={{
              border: `1px solid #272727`,
              cursor: "pointer",
              width: "240px",
              height: "240px",
              margin: "auto",
            }}
          />
        );
      } else {
        const initials =
          (userInfo?.firstName?.charAt(0) || "") +
          (userInfo?.lastName?.charAt(0) || "");

        return (
          <Avatar
            alt="user-initials"
            sx={{
              border: `1px solid #272727`,
              cursor: "pointer",
              width: "220px",
              height: "220px",
              fontSize: "120px",
              lineHeight: "220px",
            }}
          >
            {initials}
          </Avatar>
        );
      }
    }
  };

  return (
    <Container
      maxWidth="100%"
      sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}
    >
      <Stack
        direction="column"
        spacing={3}
        alignItems="right"
        sx={{ ml: 10, mt: 4 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div>
            <Typography
              sx={{
                fontSize: theme.typography.bodyText15.fontSize,
                mb: 1,
                letterSpacing: "1px",
              }}
              color="textSecondary"
            >
              Profile Picture
            </Typography>
            {getUserAvatar()}
          </div>

          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            sx={{ ml: 5 }}
          >
            <input
              type="file"
              id="uploadInput"
              style={{ display: "none" }}
              onChange={handleImageUpload}
              ref={fileInputRef}
            />
            <label htmlFor="uploadInput">
              <Button
                variant="outlined"
                startIcon={<AddAPhotoIcon />}
                sx={{ color: "#000" }}
                onClick={triggerFileInput}
              >
                Upload Image
              </Button>
            </label>

            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={handleDeletePic}
              disabled={!uploadedImage ? true : false}
              sx={{ color: "#000" }}
            >
              Delete Image
            </Button>
          </Stack>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", maxWidth: "300px" }}
        >
          <TextField
            fullWidth
            label="First Name"
            variant="standard"
            margin="normal"
            size="small"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            fullWidth
            label="Last Name"
            variant="standard"
            margin="normal"
            size="small"
            id="lname"
            name="lname"
            value={formik.values.lname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lname && Boolean(formik.errors.lname)}
            helperText={formik.touched.lname && formik.errors.lname}
          />

          <TextField
            fullWidth
            label="Email"
            variant="standard"
            margin="normal"
            size="small"
            id="email"
            name="email"
            disabled
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            fullWidth
            label="Phone no"
            variant="standard"
            margin="normal"
            size="small"
            id="phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />

          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save
          </Button>
        </form>
      </Stack>
    </Container>
  );
};

export default ProfileInfo;
