import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  NativeSelect,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import VantageListHead from "../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  getComparator,
  sortDataByAbbreviation,
} from "../../../Methods";
import { useSelector } from "react-redux";

const InputOrders = ({ input, tableHeader }) => {
  const theme = useTheme();
  const [orderData, setOrderData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("shareCount");
  const { data } = useSelector((state) => state.todos);
  const sortedData = sortDataByAbbreviation(data);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const filteredUsers = applySortFilter(input, getComparator(order, orderBy));
    setOrderData(filteredUsers);
  }, [order, orderBy, input]);

  const handleEditRow = (index, field, value) => {
    const updatedOrders = [...orderData];
    updatedOrders[index][field] = value;
    setOrderData(updatedOrders);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h5" component="div" gutterBottom>
          Activity
        </Typography>
        <Button>Update</Button>
      </Box>

      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "100%",
          marginRight: 1,
        }}
      >
        {orderData && orderData?.length > 0 ? (
          <Box
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "3px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#c0c0c0",
                borderRadius: "10px",
                minHeight: "2px",
              },
            }}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                borderCollapse: "collapse",
                border: "solid 1px #eee",
                marginTop: "-1px",
              }}
            >
              <VantageListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHeader}
                noStatus={true}
                customizeFont={12}
                rowCount={orderData.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {orderData?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        background: theme.palette.grey[200],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row.strategy}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row.ticker}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row.position}
                    </Tablecell>

                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingRight: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      <TextField
                        // type="number"
                        value={row.shareCount}
                        onChange={(e) =>
                          handleEditRow(index, "shareCount", e.target.value)
                        }
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            width: "100px",
                            paddingTop: "5px",
                            paddingBottom: "0px",
                            border: "none",
                          },
                        }}
                        inputProps={{
                          step: "any",
                          style: {
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            MozAppearance: "textfield",
                            WebkitAppearance: "none",
                            "&::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "&::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          },
                        }}
                      />
                    </Tablecell>

                    <Tablecell
                      align="left"
                      sx={{
                        paddingLeft: "5px",
                        width: "80px",
                        "& .MuiNativeSelect-icon": { display: "none" },
                      }}
                    >
                      <NativeSelect
                        value={row.broker}
                        onChange={(e) =>
                          handleEditRow(index, "broker", e.target.value)
                        }
                        autoSelect
                        disableUnderline
                        sx={{
                          height: "100%",
                          fontSize: "12px",
                          "& .MuiNativeSelect-select": { fontSize: "12px" },
                          "& .MuiNativeSelect-icon": { fontSize: "12px" },
                        }}
                        id={`broker-${index}`}
                        name={`broker-${index}`}
                      >
                        {sortedData?.map((val) => (
                          <option
                            key={val.brokerId}
                            value={val.brokerId}
                            style={{ fontSize: "12px" }}
                          >
                            {val.abbreviation}
                          </option>
                        ))}
                      </NativeSelect>
                    </Tablecell>

                    <Tablecell
                      align="left"
                      sx={{
                        paddingLeft: "5px",
                        width: "80px",
                        "& .MuiNativeSelect-icon": { display: "none" },
                      }}
                    >
                      <NativeSelect
                        value={row.custodian}
                        onChange={(e) =>
                          handleEditRow(index, "custodian", e.target.value)
                        }
                        autoSelect
                        disableUnderline
                        sx={{
                          height: "100%",
                          fontSize: "12px",
                          "& .MuiNativeSelect-select": { fontSize: "12px" },
                          "& .MuiNativeSelect-icon": { fontSize: "12px" },
                        }}
                        id={`custodian-${index}`}
                        name={`custodian-${index}`}
                      >
                        {row?.accounts?.map((val) => (
                          <option
                            key={val}
                            value={val}
                            style={{ fontSize: "12px" }}
                          >
                            {val}
                          </option>
                        ))}
                      </NativeSelect>
                    </Tablecell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default InputOrders;
