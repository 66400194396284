import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  useTheme,
  IconButton,
  TextField,
  Button,
  Box,
  TableContainer,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CachedIcon from "@mui/icons-material/Cached";
import AddIcon from "@mui/icons-material/Add";
import VantageListHead from "../../../ListHead/SignalList";
import {
  Tablecell,
  TABLE_HEAD_SIGNALS as TABLE_HEAD,
  applySortFilter,
  getComparator,
  hashCurrencyEnumString,
  hashInvestmentReverse,
  hashExchangeEnumReverse,
  inputChecker,
} from "../../../Methods";
import "../../../../Style/Signals.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SaveSignals from "../../../Popup/SaveSignals";
import ClearConfirmationPopup from "../../../Popup/ClearConfirmationPopup";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../../Redux/Reducers/todoReducers";

const SignalTickerData = ({
  pastedArray,
  setpasted,
  strategyValObj,
  setVerifyEnabled,
  setCopiedData,
  setHandleVerify,
  setSaveChangeHandler,
  blacklist,
  isStrategist,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("ddvalue");
  const [saveChange, setSaveChange] = useState(false);
  const [clearConfirmationOpen, setClearConfirmationOpen] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const { livePrices } = useSelector((state) => state.todos);

  useEffect(() => {
    if (livePrices) {
      setCompanyData(livePrices);
    }
  }, [livePrices]);
  useEffect(() => {
    if (companyData.length > 0) {
      const updatedData = data.map((dataItem) => {
        const matchingCompany = companyData.find(
          (companyItem) =>
            companyItem.symbol?.toLowerCase() === dataItem.name?.toLowerCase()
        );

        const blacklistVerified = isStrategist
          ? false
          : blacklist.some(
              (blacklistItem) =>
                blacklistItem.ticker.symbol?.toLowerCase() ===
                dataItem.name?.toLowerCase()
            );

        return {
          ...dataItem,
          notVerified: !matchingCompany,
          blacklistVerified: blacklistVerified,
        };
      });

      setData(updatedData);
    }
  }, [companyData, blacklist, isStrategist]);

  useEffect(() => {
    const filteredUsers = applySortFilter(
      pastedArray,
      getComparator(order, orderBy)
    );

    setData(filteredUsers);
    setCopiedData(filteredUsers);
  }, [order, orderBy, pastedArray]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEditRow = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index] = { ...updatedData[index], [field]: value };
    const editedEntry = updatedData[index];
    let editedName = editedEntry.name;
    const testName = inputChecker(editedName);
    if (testName.isValid) {
      delete editedEntry.invalid;
    }
    if (!testName.isValid) {
      editedEntry.invalid = true;
    }
    // setData(updatedData);
    setCopiedData(updatedData);
    setVerifyEnabled(true);

  };

  const handleDeleteRow = (index) => {
    const updatedData = data?.filter((_, i) => i !== index);
    // setpasted(updatedData);
    setVerifyEnabled(true);
    setData(updatedData);
    setCopiedData(updatedData);
  };

  const handleAddRow = () => {
    const newRow = {
      name: "",
      value: 0,
    };
    setData([newRow, ...data]);
    // setVerifyEnabled(true);
   
  };

  const handleVerify = () => {
    const updatedData = data?.map((item) => {
      const { ticker } = inputChecker(item?.name) ?? {};
      const { symbol, currency, investment, country } = ticker ?? {};
      const curr = hashCurrencyEnumString(currency) ?? 7;
      const invest = hashInvestmentReverse(investment) ?? 4;
      const exchange = hashExchangeEnumReverse(country) ?? 4;

      return {
        symbol,
        currency: curr,
        investment: invest,
        exchange,
      };
    });

    window.clientSocks.send(
      JSON.stringify({
        action: "market_data",
        data: {
          threshold: 120,
          request: true,
          fields: [9, 10],
          tickers: updatedData,
        },
      })
    );
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Fetching Market Data"));
    toast.success("Verification Initiated");
  };

  const hasInvalidObject = data?.some((obj) => obj && obj?.invalid);
  const countInvalidObjects = (data) => {
    return data?.filter((obj) => obj && obj?.invalid).length;
  };
  const numberOfInvalidObjects = countInvalidObjects(data);
  const countNotVerifiedObjects = (data) => {
    return data?.filter((obj) => obj && obj?.notVerified).length;
  };
  const numberOfNotVerifiedObjects = countNotVerifiedObjects(data);
  const countBlacklistedObjects = (data) => {
    return data?.filter((obj) => obj && obj?.blacklistVerified).length;
  };
  const numberOfBlacklistedObjects = countBlacklistedObjects(data);

  useEffect(() => {
    // setVerifyEnabled(data.length > 0 && !hasInvalidObject);
    setHandleVerify(() => handleVerify);
    setSaveChangeHandler(() => setSaveChange);
  }, [
    data,
    hasInvalidObject,
    setVerifyEnabled,
    setHandleVerify,
    setSaveChangeHandler,
  ]);
  const handleKeyDown = (e) => {
    const disallowedKeys = ["e", "E", "+", "-"];
    if (
      disallowedKeys.includes(e.key) &&
      !(e.key === "-" && e.target.value.length === 0)
    ) {
      e.preventDefault();
    }
  };

  const handleChangeEvent = (index, e) => {
    const newValue = e.target.value;

    const regex = /^-?\d*\.?\d{0,2}$/;

    if (regex.test(newValue) && !(newValue === "0" || newValue === "-0")) {
      handleEditRow(index, "value", newValue);

    }
   
   
  };

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          overflow: "hidden",
          width: "100%",
          marginRight: 1,
          mt: 8,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 400,
            zIndex: 1,
            color: "red",
            fontSize: "small",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: "100%",
            height: "30px",
          }}
        >
          {numberOfInvalidObjects ? (
            <div
              style={{
                color: "red",
                fontSize: "small",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: theme.palette.red.shortPositions,
                  marginRight: "5px",
                  border: "1px solid black",
                }}
              />{" "}
              Number of wrong ticker inputs: {numberOfInvalidObjects}
            </div>
          ) : null}

          {numberOfNotVerifiedObjects ? (
            <div
              style={{
                color: "red",
                fontSize: "small",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: "yellow",
                  marginRight: "5px",
                  border: "1px solid black",
                }}
              />
              Number of tickers for which market data not fetched:{" "}
              {numberOfNotVerifiedObjects}
            </div>
          ) : null}

          {numberOfBlacklistedObjects ? (
            <div
              style={{
                color: "red",
                fontSize: "small",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: theme.palette.grey[90],
                  marginRight: "5px",
                  border: "1px solid black",
                }}
              />
              Number of tckers that are blacklisted:{" "}
              {numberOfBlacklistedObjects}
            </div>
          ) : null}
        </Box>

        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCollapse: "collapse",
            border: "solid 1px #eee",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#BDDDC0",
                paddingLeft: "20px",
                textAlign: "left",
                py: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Modified Signals List
              <Button
                onClick={() => setClearConfirmationOpen(true)}
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  color: "#000",
                  height: "15px",
                  fontWeight: 900,
                  py: "5px",
                  marginLeft: "auto",
                  "& .MuiButton-label": {
                    fontSize: theme.typography.bodyText12.fontSize,
                    fontWeight: theme.typography.bodyText18.fontWeight,
                  },
                }}
              >
                Clear
              </Button>
            </Tablecell>
            <Tablecell
              align="left"
              onClick={handleAddRow}
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#6B8D6E",
                paddingRight: "5px",
                textAlign: "right",
                cursor: "pointer",
                color: "white",
                py: "5px",
              }}
            >
              Add Signal
              <IconButton
                onClick={handleAddRow}
                aria-label="add"
                sx={{
                  height: "15px",
                  width: "15px",
                  marginLeft: "10px",
                }}
              >
                <AddIcon
                  sx={{ height: "15px", width: "15px", color: "white" }}
                />
              </IconButton>
            </Tablecell>
          </TableRow>
        </Table>
        <TableContainer sx={{ height: "45vh" }}>
          <Table
            size="small"
            aria-label="a dense table"
            stickyHeader
            sx={{
              height: "max-content",
              border: "solid 1px #eee",
              overflowY: "scroll",
            }}
          >
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
              noStatus={true}
              customizeFont={12}
            />

            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    overflow: "auto",
                    background: row?.invalid
                      ? theme.palette.red.shortPositions
                      : row?.notVerified
                      ? "yellow"
                      : row?.blacklistVerified
                      ? theme.palette.grey[90]
                      : "",
                    "&:hover": {
                      background: row?.invalid
                        ? theme.palette.red.shortPositions
                        : row?.notVerified
                        ? "yellow"
                        : row?.blacklistVerified
                        ? theme.palette.grey[90]
                        : theme.palette.grey[200],

                      cursor: "pointer",
                    },
                    "& > .MuiTableCell-root": {
                      paddingTop: "1px",
                      paddingBottom: "0px !important",
                      lineHeight: "1.2",
                      "& .MuiInputBase-input": {
                        padding: "0px 0px !important",
                      },
                      "& .MuiIconButton-root": {
                        padding: "0px",
                      },
                    },
                  }}
                >
                  <TableCell align="right" sx={{ borderRight: "none" }}>
                    <TextField
                      value={row.name}
                      onChange={(e) => {
                        handleEditRow(index, "name", e.target.value);
                      }}
                      size="small"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        },
                      }}
                    />
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{ borderLeft: "none", height: "-50px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "left",
                      }}
                    >
                      <TextField
                        value={row?.value}
                        onChange={(e) => handleChangeEvent(index, e)}
                        onKeyDown={handleKeyDown}
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            fontWeight: "500",
                            width: "10ch",
                            marginRight: "0.5ch",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                        inputProps={{
                          type: "number",
                          step: "any",
                          style: {
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            MozAppearance: "textfield", // For Firefox
                            WebkitAppearance: "none", // For most other browsers
                            // For hiding the spin buttons specifically in WebKit browsers
                            "&::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "&::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          },
                        }}
                      />

                      <Box
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          width: "10px",
                          height: "10px",
                          mt: "2px",
                        }}
                      >
                        %
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell
                    align="left"
                    className="lastTableCell"
                    sx={{
                      borderRight: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      "&:hover": {
                        ".deleteIcon": {
                          visibility: "visible",
                        },
                      },
                    }}
                  >
                    {row?.value >= 0 ? "L" : "S"}

                    <Box
                      component="span"
                      sx={{
                        fontSize: "9px",
                        fontWeight: "500",
                        visibility: "hidden",
                      }}
                      className="deleteIcon"
                    >
                      <IconButton
                        onClick={() => handleDeleteRow(index)}
                        aria-label="delete"
                        sx={{
                          height: "15px",
                          width: "15px",
                        }}
                      >
                        <DeleteOutlineIcon
                          sx={{ height: "15px", width: "15px" }}
                        />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "fixed", // Changed from absolute to fixed
            top: 97, // Adjust as needed
            right: 200, // Adjust as needed
            zIndex: 1000, // Ensure it's above other elements
          }}
        >
          <Button
            variant="standard"
            startIcon={<CachedIcon />}
            disabled={data.length < 1 || hasInvalidObject}
            onClick={handleVerify}
            sx={{ mr: 2 }}
          >
            Verify Signals
          </Button>
          <Button
            variant="contained"
            startIcon={<CachedIcon />}
            disabled={data.length < 1 || hasInvalidObject}
            onClick={() => {
              setSaveChange(true);
            }}
          >
            SAVE CHANGES
          </Button>
        </Box> */}
      </Box>
      <ClearConfirmationPopup
        open={clearConfirmationOpen}
        onClose={() => setClearConfirmationOpen(false)}
        title="Confirm Action"
        onConfirm={() => {
          setpasted([]);
          setClearConfirmationOpen(false);
        }}
      />
      <SaveSignals
        saveChange={saveChange}
        setSaveChange={setSaveChange}
        data={data}
        title="Update Signals"
        setpasted={setpasted}
        strategyValObj={strategyValObj}
        numberOfNotVerifiedObjects={numberOfNotVerifiedObjects}
      />
    </>
  );
};

export default SignalTickerData;
