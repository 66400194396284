import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Tablecell } from "../Methods";

const AllocatorList = ({ order, orderBy, headLabel, onRequestSort }) => {
  const theme = useTheme();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property); 
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          "&:hover": {
            background: theme.palette.grey[200],
            cursor: "pointer",
          },
        }}
      >
        <TableCell
          align="left"
          colSpan={1}
          sx={{
            fontSize: theme.typography.bodyText12.fontSize,
            fontWeight: 900,
            color: "#fff",
            padding: 0,
            paddingLeft: "20px",
            textAlign: "left",
          }}
        />
        <TableCell
          align="center"
          colSpan={4}
          sx={{
            fontSize: theme.typography.bodyText12.fontSize,
            fontWeight: 900,
            backgroundColor: "#B5D0D9",
            padding: 0,
            paddingLeft: "20px",
            textAlign: "center",
          }}
        >
          Existing
        </TableCell>
        <Tablecell
          align="center"
          colSpan={5}
          sx={{
            fontSize: theme.typography.bodyText12.fontSize,
            fontWeight: 900,
            backgroundColor: "#E4C0C6",
            padding: 0,
            paddingLeft: "20px",
            textAlign: "center",
          }}
        >
          Target
        </Tablecell>
        <Tablecell
          align="center"
          colSpan={2}
          sx={{
            fontSize: theme.typography.bodyText12.fontSize,
            fontWeight: 900,
            backgroundColor: "#bfffbf",
            padding: 0,
            paddingLeft: "20px",
            textAlign: "center",
          }}
        >
          Preferences
        </Tablecell>
      </TableRow>

      <TableRow sx={{ background: theme.palette.grey[700] }}>
        {headLabel?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: theme.palette.lightGrey.textColor,
              background: "#d9dcdf",
              fontSize: "11px",
              width: headCell.id === "ne" ? "10px" : "",
              padding: "2px",
              borderLeft: "0.1px solid #ccc3c3",
              borderRight: "0.1px solid #ccc3c3",
              alignItems: "center",
              "&:hover": {
                background: theme.palette.lightGrey.tableHeader,
                cursor: "pointer",
              },
            }}
          >
            <TableSortLabel
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black !important",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  {headCell.label}
                </Typography>
              </Box>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AllocatorList;
