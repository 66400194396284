import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { ButtonAction } from "../../../Redux/Actions/todoActions";
import TargetAllocation from "./TargetAllocation";
import TotalFund from "./TotalFund";
import {
  calculateAndFormatNoAbs,
  findStrategy,
  formatValue,
  twoDecimal,
} from "../../Methods";
import { toast } from "react-toastify";
import { GetAllocation } from "../../Layouts/dashboardCalculations";
import Existing from "./TotalFund/Existing";
import Target from "./TotalFund/Target";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg
} from "../../../Redux/Reducers/todoReducers";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { colorPalette } from "../../Methods/colors";


const FundAllocation = () => {
  const theme = useTheme();
  const [activeAum, setActiveAum] = useState(0);
  const [matchedAllocations, setMatchedAllocations] = useState([]);
  const [isAllocationValid, setIsAllocationValid] = useState(true);
  const [fundId, setFundId] = useState();
  const [oldData, setOldData] = useState([]);
  const [existing, setExisting] = useState([]);
  const [fundAum, setFundAum] = useState(0);
  const [totals, setTotals] = useState([]);
  const [fundVal, setFundVal] = useState();
  const [Strategies, setStrategy] = useState([]);
  const [StrategiesLowAum, setStrategyLowAum] = useState([]);
  const [StrategiesCombined, setStrategyCombined] = useState([]);
  const [autoCompletedValue, setautoCompletedValue] = useState([]);
  const [donutTwo, setdonutTwo] = useState([25, 25, 25, 25]);
  const [editableValue, setEditableValue] = useState(0);
  const [Piedata, setPieData] = useState([]);
  const [PiedataTarget, setPieDataTarget] = useState([]);
  const Buttonflag = useSelector((state) => state?.todos?.buttonflag);
  const [showRecalculate, setShowRecalculate] = useState(true);
    const [editableData, setEditableData] = useState([]);
  
  useEffect(() => {
    setActiveAum(fundAum);
  }, [fundAum]);
  const {
    funds,
    strategies,
    accountingData,
    allocation,
    lots,
    marketData,
    data,
  } = useSelector((state) => state?.todos);
  const dispatch = useDispatch();
  const sortedFunds = funds
    ?.map((i) => i?.abbreviation)
    ?.filter((abbreviation) => abbreviation)
    ?.sort((a, b) => a.localeCompare(b));
  const sortedData = data?.slice().sort((a, b) => {
    if (a.abbreviation && b.abbreviation) {
      return a.abbreviation.localeCompare(b.abbreviation);
    } else if (a.abbreviation) {
      return -1;
    } else if (b.abbreviation) {
      return 1;
    } else {
      return 0;
    }
  });
  useEffect(() => {
    if (Strategies.length > 0) {
      const sortedStrategies = [...Strategies].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const highAumStrategies = sortedStrategies.filter(
        (strategy) => strategy?.aum >= 10
      );
      const lowAumStrategies = sortedStrategies.filter(
        (strategy) => strategy?.aum < 10
      );
      setStrategyLowAum(lowAumStrategies);
      setStrategyCombined(highAumStrategies);
    }
  }, [Strategies]);

  useEffect(() => {
    dispatch(ButtonAction(false));
  }, [dispatch]);

  useEffect(() => {
    if (Buttonflag) {
      setShowRecalculate(true);
    }
  }, [Buttonflag, allocation]);

  useEffect(() => {
    const fundList = funds
      ?.map((i) => i?.abbreviation)
      ?.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
    setFundVal(fundList?.[0]);
  }, [funds]);

  const getFund = (fund) => {
    window.clientSocks.send(
      `{"action":"get_allocation", "data":${JSON.stringify({
        fund: fund,
      })}}`
    );
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Fetching Fund Allocation"));
  };

  const updateTotals = (updatedAllocations) => {
    const totalLong = updatedAllocations.reduce(
      (sum, strategy) =>
        sum +
        (parseFloat(strategy.percentLong) *
          parseFloat(strategy.totalAllocation / 100) || 0),
      0
    );

    const totalShort = updatedAllocations.reduce(
      (sum, strategy) =>
        sum +
        (parseFloat(strategy.percentShort) *
          parseFloat(strategy.totalAllocation / 100) || 0),
      0
    );
    const longFundAum = parseFloat(editableValue) * (totalLong / 100);
    const shortFundAum = parseFloat(editableValue) * (totalShort / 100);
    const totalforPercent = totalLong + totalShort;
    const totalAums = longFundAum + shortFundAum;
    const netPercent = totalLong - totalShort;
    const netFundAum = longFundAum - shortFundAum;

    const newTotals = [
      {
        changeValue: twoDecimal(totalLong),
        changeAllocation: calculateAndFormatNoAbs(longFundAum),
      },
      {
        changeValue: twoDecimal(totalShort),
        changeAllocation: calculateAndFormatNoAbs(shortFundAum),
      },
      {
        changeValue: twoDecimal(totalforPercent),
        changeAllocation: calculateAndFormatNoAbs(totalAums),
      },
      {
        changeValue: twoDecimal(netPercent),
        changeAllocation: calculateAndFormatNoAbs(netFundAum),
      },
    ];

    setTotals(newTotals);
  };
  const updateLongShort = (updatedAllocations) => {
    const totalLong = updatedAllocations.reduce(
      (sum, strategy) =>
        sum +
        (parseFloat(strategy.long) *
          parseFloat(strategy.existingPercent / 100) || 0),
      0
    );
    const totalShort = updatedAllocations.reduce(
      (sum, strategy) =>
        sum +
        (parseFloat(strategy.short) *
          parseFloat(strategy.existingPercent / 100) || 0),
      0
    );
    const longFundAum = fundAum * (totalLong / 100);
    const shortFundAum = fundAum * (totalShort / 100);
    const totalforPercent = totalLong + totalShort;
    const totalAums = longFundAum + shortFundAum;
    const netPercent = totalLong - totalShort;
    const netFundAum = longFundAum - shortFundAum;

    const newTotals = [
      {
        name: "Total for Long",
        changeValue: twoDecimal(totalLong),
        changeAllocation: calculateAndFormatNoAbs(longFundAum),
      },
      {
        name: "Total for Short",
        changeValue: twoDecimal(totalShort),
        changeAllocation: calculateAndFormatNoAbs(shortFundAum),
      },
      {
        name: "Total Gross",
        changeValue: twoDecimal(totalforPercent),
        changeAllocation: calculateAndFormatNoAbs(totalAums),
      },
      {
        name: "Total Net",
        changeValue: twoDecimal(netPercent),
        changeAllocation: calculateAndFormatNoAbs(netFundAum),
      },
    ];

    setOldData(newTotals);
  };

  useEffect(() => {
    if (allocation && allocation.items && StrategiesCombined.length > 0) {
      const fundAllocations = allocation.items;
      const sortedStrategies = [...StrategiesCombined].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      const strategyColorMap = sortedStrategies?.reduce(
        (acc, strategy, index) => {
          const color = colorPalette[index % colorPalette.length];
          acc[strategy?.name] = color;
          return acc;
        },
        {}
      );

      const newAllocations = sortedStrategies?.map((strategy) => {
        const allocationData = fundAllocations[strategy.strategyId];

        if (allocationData) {
          const {
            ignoreWhenRebalancing,
            percentLong,
            percentShort,
            totalAllocation,
            broker,
            account,
            ...rest
          } = allocationData;
          const { long, short } = GetAllocation(
            fundId,
            strategy?.name,
            lots,
            accountingData,
            funds,
            strategies,
            marketData
          );

          const existingPercent = calculateAndFormatNoAbs(
            (strategy?.aum / fundAum) * 100
          );

          const fundAumCal = parseFloat(activeAum) || 0;
          const alcTarget = (fundAumCal * Number(totalAllocation)) / 100;
          const alcExisting = strategy?.aum;
          const formattedLong = twoDecimal((long / strategy?.aum) * 100);
          const formattedShort = twoDecimal(
            (Math.abs(short) / strategy?.aum) * 100
          );
          const strategyColor = strategyColorMap[strategy?.name];

          return {
            ...strategy,
            ...rest,
            ignoreWhenRebalancing: Boolean(ignoreWhenRebalancing),
            existingAllocation: alcExisting
              ? twoDecimal(alcExisting)
              : twoDecimal(0),
            allocation: alcTarget ? twoDecimal(alcTarget) : twoDecimal(0),
            totalAllocation: totalAllocation
              ? twoDecimal(Number(totalAllocation))
              : twoDecimal(0),
            percentShort: percentShort
              ? twoDecimal(Number(percentShort))
              : twoDecimal(0),
            percentLong: percentLong
              ? twoDecimal(Number(percentLong))
              : twoDecimal(0),
            existingPercent: Number(existingPercent) || 0,
            long: Number(formattedLong) || 0,
            short: Number(formattedShort) || 0,
            broker: broker,
            account: account,
            color: strategyColor,
          };
        } else {
          const { long, short } = GetAllocation(
            fundId,
            strategy?.name,
            lots,
            accountingData,
            funds,
            strategies,
            marketData
          );

          const formattedLong = twoDecimal((long / strategy?.aum) * 100);
          const formattedShort = twoDecimal(
            (Math.abs(short) / strategy?.aum) * 100
          );

          const fundAumCal = parseFloat(activeAum) || 0;
          const existingPercent = (strategy?.aum / fundAumCal) * 100;
          const strategyColor = strategyColorMap[strategy?.name];

          return {
            ...strategy,
            broker: "",
            account: "",
            existingAllocation: strategy?.aum,
            allocation: 0,
            totalAllocation: twoDecimal(0),
            percentShort: twoDecimal(0),
            ignoreWhenRebalancing: false,
            percentLong: twoDecimal(0),
            existingPercent: existingPercent,
            long: Number(formattedLong) || 0,
            short: Number(formattedShort) || 0,
            color: strategyColor,
          };
        }
      });
      const pieExisting = newAllocations?.map((strategy) => ({
        name: strategy?.name,
        value: Number(strategy?.existingPercent) || 0,
        color: strategy.color,
      }));

      const pieTarget = newAllocations?.map((strategy) => ({
        name: strategy?.name,
        value: Number(strategy?.totalAllocation) || 0,
        color: strategy.color,
      }));

      setPieData(pieExisting);
      setPieDataTarget(pieTarget);
      setMatchedAllocations(newAllocations);
      setEditableData(newAllocations);
      setExisting(newAllocations);
      updateTotals(newAllocations);
      updateLongShort(newAllocations);
    } else {
      const zeroedAllocations = Strategies.map((strategy) => ({
        ...strategy,
        broker: "",
        account: "",
        totalAllocation: twoDecimal(0),
        percentShort: twoDecimal(0),
        ignoreWhenRebalancing: false,
        percentLong: twoDecimal(0),
        existingPercent: 0,
        long: 0,
        short: 0,
        color: "#E6E6E6",
      }));

      setMatchedAllocations(zeroedAllocations);
      setExisting(zeroedAllocations);
      setOldData([
        { name: "Total for Long", changeValue: 0, changeAllocation: 0 },
        { name: "Total for Short", changeValue: 0, changeAllocation: 0 },
        { name: "Total Gross", changeValue: 0, changeAllocation: 0 },
        { name: "Total Net", changeValue: 0, changeAllocation: 0 },
      ]);
      setTotals([
        { changeValue: 0, changeAllocation: 0 },
        { changeValue: 0, changeAllocation: 0 },
        { changeValue: 0, changeAllocation: 0 },
        { changeValue: 0, changeAllocation: 0 },
      ]);
      setPieData([]);
    }
  }, [
    allocation,
    Strategies,
    activeAum,
    fundAum,
    fundId,
    lots,
    accountingData,
    funds,
    marketData,
    StrategiesCombined,
  ]);

  useEffect(() => {
    if (fundAum) {
      setEditableValue(fundAum);
    }
  }, [fundAum]);

  const handleFundChange = (newValue) => {
    setFundVal(newValue);

    const item = funds?.find((e) => e.abbreviation === newValue);
    if (item?.accounts && Object.keys(item?.accounts).length) {
      const transformedAccounts = Object.keys(item.accounts)
        .map((key) => ({
          name: key,
          custodian: item.accounts[key].custodian,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setautoCompletedValue(transformedAccounts);
    } else {
      setautoCompletedValue([]);
    }

    if (item && item?.fundId) {
      setFundId(item?.fundId);
      getFund(item?.fundId);
      const foundFund = accountingData[0]?.funds?.find(
        (row) => row?.fund === item?.fundId
      );

      if (foundFund) {
        setFundAum(foundFund?.aum);
        setActiveAum(foundFund?.aum);

        const strategyAUMsData = Object.keys(foundFund?.strategyAUMs || {})
          .filter((strategyId) =>
            strategies.some((strategy) => strategy?.strategyId === strategyId)
          )
          .map((strategyId) => {
            return {
              strategyId: strategyId,
              name: findStrategy(strategyId, strategies),
              aum: foundFund?.strategyAUMs[strategyId],
            };
          });
        if (strategyAUMsData?.length > 0) {
          setMatchedAllocations([]);
          setExisting([]);
          setStrategy(strategyAUMsData);
        } else {
          setStrategy([]);
          setMatchedAllocations([]);
          setExisting([]);
        }
      } else {
        setFundAum(0);
        setEditableValue(0);
        setActiveAum(0);
        setFundId();
      }
    }
  };

  const handleSave = () => {
    const totalAllocationSum = matchedAllocations.reduce((sum, strategy) => {
      return sum + parseFloat(strategy.totalAllocation) / 100;
    }, 0);

    if (totalAllocationSum > 1) {
      setIsAllocationValid(false);
      toast.error("Allocation must not exceed 100%");
      return;
    } else {
      setIsAllocationValid(true);

      const formattedAllocations = matchedAllocations.reduce(
        (acc, strategy) => {
          const {
            strategyId,
            account,
            totalAllocation,
            percentShort,
            percentLong,
            ignoreWhenRebalancing,
            broker,
          } = strategy;

          if (parseFloat(totalAllocation) !== 0) {
            acc[strategyId] = {
              account: account || autoCompletedValue[0]?.name,
              totalAllocation: parseFloat(totalAllocation) / 100,
              percentShort: parseFloat(percentShort) / 100,
              percentLong: parseFloat(percentLong) / 100,
              ignoreWhenRebalancing,
              broker: broker || sortedData[0]?.brokerId,
            };
          }

          return acc;
        },
        {}
      );
      const finalData = {
        action: "set_allocation",
        data: {
          fundAllocation: formattedAllocations,
          fund: fundId,
          targetedAUM: editableValue,
        },
      };

      window.clientSocks.send(JSON.stringify(finalData));
      dispatch(incrementActiveRequests());
      dispatch(updateLoading(true));
      dispatch(updateLoadingMsg("Updating Fund Allocation"));
    }
  };

  const totalAllocationSumTarget = matchedAllocations?.reduce(
    (sum, strategy) => {
      return sum + (parseFloat(strategy?.totalAllocation) || 0);
    },
    0
  );
  const totalAllocationSumExisting = matchedAllocations?.reduce(
    (sum, strategy) => {
      return sum + (parseFloat(strategy?.existingPercent) || 0);
    },
    0
  );

  return (
    <>
      <Container maxWidth="100%" sx={{ marginTop: 5, marginBottom: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "20px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>View and Edit Fund Allocations</Typography>
              
              </div>
              <div>
                <Button variant="contained" onClick={handleSave}>
                  SAVE CHANGES
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              value={fundVal}
              options={sortedFunds}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => handleFundChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Fund"
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "25vh",
                alignItems: "center",
              }}
            >
              <TotalFund totals={totals} oldData={oldData} fundAum={fundAum} />
              <Existing totals={totals} oldData={oldData} fundAum={fundAum} />
              <Target
                totals={totals}
                oldData={oldData}
                editableValue={editableValue}
                setEditableValue={setEditableValue}
                setActiveAum={setActiveAum}
              />

              {Piedata.length > 0 ? (
                <div
                  style={{
                    width: "35%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography sx={{ fontSize: "24px", fontWeight: 900 }}>
                      Existing Allocation
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                      {`Total Allocation: ${totalAllocationSumExisting?.toFixed(
                        2
                      )}%`}
                    </Typography>
                    <PieChart width={300} height={300}>
                      <Pie
                        data={Piedata}
                        cx={150}
                        cy={150}
                        innerRadius={60}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {Piedata.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry?.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </div>

                  <div>
                    <Typography sx={{ fontSize: "24px", fontWeight: 900 }}>
                      Target Allocation
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                      {`Total Allocation: ${totalAllocationSumTarget?.toFixed(
                        2
                      )}%`}
                    </Typography>
                    <PieChart width={300} height={300}>
                      <Pie
                        data={PiedataTarget}
                        cx={150}
                        cy={150}
                        innerRadius={60}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {PiedataTarget.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry?.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </div>
                </div>
              ) : (
                <Typography>No data available</Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography sx={{ fontSize: "25px", fontWeight: 900 }}>
                Fund Allocation
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "25vh",
                }}
              >
                <TargetAllocation
                  custodians={autoCompletedValue}
                  sortedData={sortedData}
                  matchedAllocations={matchedAllocations}
                  setMatchedAllocations={setMatchedAllocations}
                  updateTotals={updateTotals}
                  fundAum={fundAum}
                  setPieDataTarget={setPieDataTarget}
                  editableValue={editableValue}
                  setEditableData={setEditableData}
                  editableData={editableData}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FundAllocation;
