import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box, Alert, Link } from "@mui/material";
import { ResetPassword } from "../../Schemas";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { resetPassword } from "../../../utils/restAPI";
import { updateLoadingAxios } from "../../../Redux/Reducers/todoReducers";
import { useDispatch } from "react-redux";

const ResetUserPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [passReset, setPassReset] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      code: "",
    },
    validationSchema: ResetPassword,
    onSubmit: async ({ code, password, confirmPassword }) => {
      dispatch(updateLoadingAxios(true));
      try {
        const response = await resetPassword(email, code, confirmPassword);
        dispatch(updateLoadingAxios(false));

        if (response && response.success) {
          setPassReset(true);
          setEmailVerified(false);
        } else {
          setPassReset(false);
          setEmailVerified(true);
        }
      } catch (error) {
        setPassReset(false);
        setEmailVerified(true);
      }
    },
  });

  useEffect(() => {
    if (
      !location.state ||
      !location.state.fromButton ||
      !location.state.email
    ) {
      navigate("/login", { replace: true });
    } else {
      const userEmail = location.state.email;
      setEmail(userEmail);
    }
  }, [navigate, location.state]);
  const handleBackToLoginClick = () => {
    navigate("/login");
  };
  const theme = useTheme();
  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{ marginTop: 10, p: 2 }}>
        <Typography
          component="h1"
          variant="h4"
          fontWeight={900}
          align="center"
          gutterBottom
          color={theme.palette.primary.main}
        >
          RESET YOUR PASSWORD
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Enter a new password to reset the password on your account. We'll ask
          for this password whenever you log in.
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            label="Reset Code"
            variant="outlined"
            margin="normal"
            id="code"
            name="code"
            type="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            margin="normal"
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            fullWidth
            label="Confirm Password"
            variant="outlined"
            margin="normal"
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <Alert severity="error">{formik.errors.confirmPassword}</Alert>
          )}
          {passReset && (
            <Alert severity="success">
              Password is reset.{" "}
              <Link
                onClick={handleBackToLoginClick}
                color="primary"
                sx={{ cursor: "pointer" }}
              >
                Go back to login
              </Link>
            </Alert>
          )}
          {emailVerified && !passReset && (
            <Alert severity="error" sx={{ my: 1 }}>
              Something Went Wrong..
            </Alert>
          )}

          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2 }}
            disabled={formik.isSubmitting}
          >
            Reset Password
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default ResetUserPassword;
